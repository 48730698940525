<!-- Path: mfa-mobile -->
<template>
  <div class="wallpaper">
    <div class="content" id="main">
      <div class="phone-logo">
        <img
          class="phone-logo"
          srcset="@/assets/images/phone.png 1x, @/assets/images/phone-2x.png 2x"
          src="@/assets/images/phone-2x.png"
          alt="illustration of mobile phone"
        />
      </div>
      <div class="content-title">
        <h1>Enter verification Code</h1>
      </div>
      <div class="content-text" tabindex="0">
        <p class="content-text-p">
          To confirm your identity, we've sent a verification code to the mobile phone number
          provided on your account.
        </p>
      </div>
      <!-- otp input -->
      <div class="send-code">
        <otpInput :digits="6" @update="getOTP" :error="invalidOTP" />
      </div>
      <div v-if="invalidOTP" class="error-message" data-test="code-to-mobile-err">
        <p role="alert" class="form-error-txt">
          {{ errMsg }}
        </p>
      </div>
      <div
        v-if="resendCodeStatus && resendCodeShows"
        class="resend-code-text"
        data-test="code-to-mobile-resent"
      >
        <b role="alert">Code has been resent!</b>
      </div>
      <div
        v-if="!resendCodeStatus && resendCodeShows"
        class="resend-code-text"
        data-test="code-to-email-not-resent"
      >
        <b role="alert">We are unable to resend the verification code. Please try again later.</b>
      </div>
      <div class="resend-code">
        <span>Didn't receive anything?</span>
        <button
          class="resend-code-btn"
          @click="resendCode"
          id="mobile-resend-code-btn"
          data-test="code-to-mobile-resend-btn"
        >
          Resend Code
        </button>
      </div>

      <div class="button-container">
        <button
          :class="!processing ? 'submitBtn' : 'grayOutBtn'"
          type="submit"
          id="mobile-verify-code-btn"
          @click="verifyCode"
          data-test="code-to-mobile-verify"
        >
          {{ processing ? "PROCESSING..." : "VERIFY" }}
        </button>
        <button class="backBtn" @click="backRoute" id="send-code-to-phone-back-button">Back</button>
      </div>
      <Modal v-show="isModalVisible" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import otpInput from "@/components/Inputs/otpInput.vue"
import api from "@/app/funding_api.js"
import Modal from "@/components/modals/SomethingWrongModal.vue"
import jQuery from "jquery"
import helpers from "@/app/helpers"

export default {
  name: "SendCodeToPhone",
  components: { otpInput, Modal },
  data() {
    return {
      mfaInput: "",
      isOTPValid: true,
      resendCodeStatus: false,
      resendCodeShows: false,
      accData: [],
      grayBtn: true,
      isModalVisible: false,
      invalidOTP: false,
      errMsg: "Passcode incorrect. Please try again, or resend code.",

      processing: false,
    }
  },

  computed: {
    ...mapGetters("fundnow", [
      "getFirstName",
      "getIsVerified",
      "getAccountNum",
      "getCustomerData",
      "getMFAInfo",
      "getMFAPhone",
      "getMFAEmail",
      "getMFAMethod",
      "getFundingID",
      "getResendInfo",
      "getTotal",
      "getHasLinkedExternalAccounts",
      "getTransferFrom",
      "getBankSelected",
      "getHasInternal",
    ]),
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
    external_account_number: {
      get() {
        return this.getAccountNum
      },
      set(v) {
        this.setAccountNum(v)
      },
    },
    external_account_type: {
      get() {
        return this.getAccountType
      },
      set(v) {
        this.setAccountType(v)
      },
    },
    external_bank: {
      get() {
        return this.getBankSelected
      },
      set(v) {
        this.setBankSelected(v)
      },
    },
    is_verified: {
      get() {
        return this.getIsVerified
      },
      set(v) {
        this.setIsVerified(v)
      },
    },
    first_name: {
      get() {
        return this.getFirstName
      },
      set(v) {
        this.setFirstName(v)
      },
    },
    total: {
      get() {
        return this.getTotal
      },
      set(v) {
        this.setTotal(v)
      },
    },
    mfa_err_counter: {
      get() {
        return this.getMfaErrCounter
      },
      set(v) {
        this.setMfaErrCounter(v)
      },
    },
    resendInfoStatus: {
      get() {
        return this.getResendInfo
      },
      set(value) {
        this.setResendInfo(value)
      },
    },
    fundingID: {
      get() {
        return this.getFundingID
      },
      set(val) {
        this.setFundingID(val)
      },
    },
    hasExternalAccounts: {
      get() {
        return this.getHasLinkedExternalAccounts
      },
      set(v) {
        this.setHasLinkedExternalAccounts(v)
      },
    },
    transferFrom: {
      get() {
        return this.getTransferFrom
      },
      set(v) {
        this.setTransferFrom(v)
      },
    },
    hasInternalAccounts: {
      get() {
        return this.getHasInternal
      },
      set(v) {
        this.setHasInternal(v)
      },
    },
  },
  methods: {
    ...mapActions("fundnow", [
      "setFundingID",
      "setFirstName",
      "setIsVerified",
      "setBankSelected",
      "setAccountType",
      "setAccountNum",
      "setMFAInfo",
      "setMFAPhone",
      "setMFAEmail",
      "setMFAMethod",
      "setJwtToken",
      "setCustomerAccounts",
      "setTotal",
      "setCustomerData",
      "setMfaErrCounter",
      "setResendInfo",
      "setProductName",
      "setPriorityChecking",
      "setCDProduct",
      "setFromFHB",
      "setFromOther",
      "setFromCheck",
      "setCustomerData",
      "setCustomerType",
      "setSelectedAccount",
      "setExtSelectedAccount",
      "setHasLinkedExternalAccounts",
      "setTransferFrom",
      "setEnrollmentSSO",
      "setManuallyFundedAccounts",
      "setHasInternal",
    ]),
    backRoute() {
      this.$router.push("/return/enhanced-security")
    },

    closeModal() {
      this.isModalVisible = false
    },

    getOTP(otp, otpReady) {
      if (otpReady) {
        this.grayBtn = false
        this.mfaInput = otp
      } else {
        this.grayBtn = true
      }
    },

    async verifyOTPCode() {
      this.errMsg = ""
      this.processing = true
      const resp = await api.returnToAppMFAVerify(this.fundingID, this.mfaInput)
      this.processing = false
      if (resp.status === 200) {
        if (resp.data?.response?.enrollment_sso) {
          this.setEnrollmentSSO(resp.data.response.enrollment_sso)
        }
        this.setJwtToken(resp.data.access_token)

        // set opened_accounts
        const accounts = resp.data.response.accounts
        this.setCustomerAccounts(accounts)

        // set funded_accounts
        const manually_funded_accounts = resp.data.response.manually_funded_accounts
        this.setManuallyFundedAccounts(manually_funded_accounts)

        if (accounts?.length > 0) {
          for (let i = 0; i < accounts.length; i++) {
            // create deposit amount field to pre fill inputs on intial deposit
            // format product type
            accounts[i].deposit_amount =
              accounts[i].intended_deposit_amount != 0
                ? helpers.formatMoneyToString(accounts[i].intended_deposit_amount)
                : ""
            this.total += accounts[i].intended_deposit_amount
            accounts[i].apy = helpers.removeLeadingZeros(accounts[i].apy)
          }
        } else if (manually_funded_accounts?.length > 0) {
          // if accounts.length == 0 push to completed
          this.$router.push("/accounts-funded-manually")
          return
        }

        let temp_data = this.customer_data
        temp_data.opened_accounts = accounts
        // need to set these so that confirm account pages have data to display and are able to mount
        this.external_account_number = resp.data.response.extn_acc
        this.external_account_type = resp.data.response.extn_product
        this.external_bank = resp.data.response.extn_fi_name
        this.hasInternalAccounts = resp.data.response.has_internal_accounts

        // set data for upcoming pages (EXTERNAL ONLY)
        if (resp.data.response.intended_funding_type == "EXTERNAL") {
          temp_data.selected_external_account = {
            account_number: this.external_account_number,
            account_name: this.external_account_type,
            fi_name: this.external_bank,
          }
        }
        temp_data.funding_type = resp.data.response.intended_funding_type
        temp_data.funding_step = resp.data.response.funding_step
        temp_data.extn_fi_name = resp.data.response.extn_fi_name
        temp_data.extn_product = resp.data.response.extn_product
        temp_data.extn_acc = resp.data.response.extn_acc
        ;(temp_data.internal_acc_desc = resp.data.response.internal_acc_desc),
          (temp_data.internal_acc = resp.data.response.internal_acc),
          (temp_data.internal_acc_type = resp.data.response.internal_acc_type),
          (temp_data.funding_method_meta = resp.data.response.funding_method_meta)
        temp_data.first_name = resp.data.response.customer_first_name
        temp_data.last_name = resp.data.response.customer_last_name
        // Need to grab field here to determine what funding method the user chose (realtime or deposits)
        // grabbing transfer method
        this.transferFrom = temp_data.funding_type

        this.customer_data = temp_data
        this.customer_data.primary_existing = resp.data.response.primary_existing
        this.is_verified = true
        this.first_name = resp.data.response.customer_first_name

        this.customer_data.matched_accounts = resp.data.response.matched_accounts
        // check matched accounts. If there are then has external account is set
        if (resp.data.response.matched_accounts) {
          this.hasExternalAccounts = resp.data.response.matched_accounts.length > 0
        }
        const funding_type = resp.data.response.intended_funding_type
        const funding_step = resp.data.response.funding_step
        // if funding did not fail, route to appropriate step
        // if funding was completed, route to view account
        switch (this.customer_data.funding_step) {
          case "":
          case "INITIATED":
            this.hasExternalAccounts = this.customer_data.matched_accounts ? true : false
            this.$router.push(`/initial-deposit`)
            break
          case "DEPOSIT_VERIFICATION":
            this.setDepositVerficationData(this.customer_data)
            this.$router.push(`/external/verify-deposits`)
            break
          case "EXTN_VERIFIED":
            // need to add condition here to determin which confirm account to go to
            if (this.customer_data.funding_method_meta === "EXTERNAL_REALTIME") {
              this.$router.push(`/external/realtime-confirm-account`)
            } else if (this.customer_data.funding_method_meta === "EXTERNAL_TRIAL") {
              this.setDepositVerficationData(this.customer_data)
              this.$router.push(`external/verify-external-account`)
            } else if (this.customer_data.funding_method_meta === "EXTERNAL_LINKED") {
              this.setExternalLinkedAccount(this.customer_data)
              this.$router.push("/external/confirm-account")
            }
            break
          case "FUNDING_CHECK":
            this.$router.push(`/view-account`)
            break
          case "FUNDING_COMPLETED":
            this.$router.push(`/view-account`)
            break
          case "FUNDING_FAILED":
            if (funding_type == "INTERNAL") this.$router.push(`/internal/transfer-unsuccessful`)
            else if (funding_type == "EXTERNAL") this.$router.push(`/external/funding-failed`)
            else
              console.log("unexpected funding_step and funding_type: ", funding_step, funding_type)
            break
          default:
            this.$router.push(`/initial-deposit`)
        }
      } else if (resp.status === 401) {
        this.mfa_err_counter += 1
        this.invalidOTP = true
      } else if (resp.status === 422) {
        this.invalidOTP = true
        this.$router.push("/max-mfa-attempts")
      } else {
        this.errMsg = "Something went wrong. Please try again."
        this.isModalVisible = true
      }
    },
    setFundingCompleteData(data, fundingType) {
      if (fundingType == "INTERNAL") {
        this.setSelectedAccount({
          description: data.internal_acc_desc,
          account_number: data.internal_acc,
          account_type: data.internal_acc_type,
        })
      } else if (fundingType == "EXTERNAL") {
        let data = this.customer_data
        data.external_accounts = [
          {
            account_number: data.extn_acc,
            fi_name: data.extn_fi_name,
          },
        ]
        this.customer_data = data
        this.setExtSelectedAccount = data.extn_product
      }
    },

    setExternalLinkedAccount(data) {
      this.setExtSelectedAccount({
        account_number: data.extn_acc,
        account_type: data.extn_product,
        fi_name: data.extn_fi_name,
      })
    },
    setDepositVerficationData(data) {
      this.external_bank = data.extn_fi_name
      this.external_account_type = data.extn_product
      this.external_account_number = data.extn_acc
    },
    async resendCode() {
      await this.requestOTPCode()
      if (this.resendInfoStatus === 200) {
        this.resendCodeStatus = true
        this.resendCodeShows = true
      } else {
        this.resendCodeStatus = false
        this.resendCodeShows = true
      }
    },

    verifyCode() {
      if (!this.grayBtn) {
        this.verifyOTPCode()
        this.resendCodeShows = false
      }
    },

    async requestOTPCode() {
      this.processing = true
      const payload = {
        method: "SMS",
      }

      const resp = await api.returnToAppMFASendCode(this.getFundingID, payload)

      if (resp) {
        this.resendInfoStatus = resp.status
        this.processing = false
      } else {
        // if no response is received
        this.resendCodeStatus = false
        this.resendCodeShows = true
      }
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
    setTimeout(() => {
      if (this.resendInfoStatus == "failure") {
        this.resendCodeShows = true
      } else {
        this.resendCodeShows = false
      }
    }, 1500)
  },
}
</script>

<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.contactUs {
  padding-top: 8px;
  display: inline-block;
  color: #333333;
}

.form-error-icon {
  margin-top: 20px;
}

.error-message {
  text-align: center;
}

.wallpaper {
  // background-image: url("~@/assets/images/orange@2x.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef5ee;
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
}

.content {
  width: max(529px, 40%);
  padding: 20px 30px 40px 30px;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.8);
}

.resend-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 450;
}

.resend-code-text {
  font-style: bold;
  margin-top: 30px;
  text-align: center;
  font-weight: 450;
  font-size: 18px;
  color: #811e24;
}

.resend-code-btn {
  border: none;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 450;
  text-decoration: underline;
}

.resend-code-btn:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.button-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#mobile-verify-code-btn {
  font-size: 0.8rem;
}

.submitBtn {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.headerLog {
  text-align: center;
  margin-top: 36px;
}
.header-img {
  @include media-breakpoint-down(sm) {
    width: 180px;
    height: 20px;
  }
}

.phone-logo {
  text-align: center;
  margin-top: 40px;
}

.phone-logo img {
  height: auto;
  width: 100px;
}

.content-title {
  text-align: center;
  font-size: 20px;
}

.content-text {
  text-align: center;
  font-size: 18px;
  font-weight: 450;
  margin-bottom: 5px;
}

.content-text-p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.send-code {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  justify-content: center;
}
</style>

<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

@include media-breakpoint-down(sm) {
  .content {
    width: 100%;
  }

  .wallpaper {
    width: 100%;
  }

  .otpInput {
    display: flex;
    justify-content: center;
    width: 100%;

    .otp-input {
      width: 38px;
      height: 44px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
    }

    .otp-error-input {
      width: 38px;
      height: 44px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
    }
  }
}

// @include media-breakpoint-down(md) {
// .content{
//   width: 100%;
// }
// .otp-input{
//   width: 34px;
//   height: 44px;
//   padding: 5px;
//   margin: 0 10px;
// }
// }

.otp-input {
  width: 54px;
  height: 64px;
  padding: 5px;
  margin: 0 10px;
  font-size: 30px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #333333;
  border: 1.5px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-error-input {
  width: 54px;
  height: 64px;
  padding: 5px;
  margin: 0 10px;
  font-size: 30px;
  box-sizing: border-box;
  border-radius: 3px;
  color: #333333;
  border: 1.5px solid #a1252c;
  background: #ffffff;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  text-align: center;
  background-color: #fbf2f2;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-error-input::-webkit-inner-spin-button,
.otp-error-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grayOutBtn {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #e7e7e7;
  border: 1px solid white;
  color: #44444b;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;

  img {
    vertical-align: middle;
  }
}

.grayOutBtn:hover {
  cursor: default !important;
  background-color: #e7e7e7 !important;
}

.backBtn {
  display: block;
  border: none;
  flex-direction: column;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 450;
  cursor: pointer;
  padding-bottom: 10px;
  background-color: #ffffff;
}

#send-code-to-phone-btn:hover {
  background-color: #ffffff;
  color: #811e24;
}

#mobile-resend-code-btn:hover {
  background-color: #ffffff;
  color: #811e24;
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
</style>
