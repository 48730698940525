<!-- Path: /external/transfer-account -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">Transfer from my account at another bank</div>
          <form @submit.prevent="submitPage">
            <div class="v-spacer-30">
              <div v-if="missingSelectedAccount" class="error">
                <img
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  title="select account"
                />
                Please select an account
              </div>
            </div>
            <div class="section-header">Select or link to an account at another bank</div>
            <div>Total Deposit Amount {{ formattedTotal }}</div>
            <!-- mock for loop to display accounts -->
            <div v-for="(account, i) in accounts" :key="i" class="form-content-container">
              <div class="radio-line">
                <input
                  :name="`radio-account`"
                  :id="`radio-account-${i}`"
                  type="radio"
                  :value="`${account.internal_acc_id}`"
                  v-model="chosenAccount"
                  :disabled="processing"
                  aria-describedby="missing-selected-option"
                />
                <label :for="`radio-account-${i}`"
                  >{{ account.fi_name }} *{{ account.account_number }}
                </label>
              </div>
            </div>
            <div class="form-content-container">
              <div class="radio-line">
                <input
                  :name="`radio-new-account-name`"
                  :id="`radio-new-account-id`"
                  type="radio"
                  :value="'new'"
                  v-model="chosenAccount"
                  :disabled="processing"
                />
                <label :for="`radio-new-account-id`">Transfer from another bank account </label>
              </div>
            </div>
            <div class="v-spacer-30" />
            <!-- agreement customer has to select in order to continue -->
            <LabeledCheckbox
              statement="I have read and accept the "
              id="accept-agreement"
              link="http://fhb.com/etfagreement"
              linkText="Electronic Transfer Authorization Agreement"
              v-model="read_agreement"
              :missing="missingAgreement"
              name="accept-agreement"
              error="You must read and accept the Electronic Transfer Authorization Agreement to proceed"
              @missingAgreementEvent="missingAgreementEvent"
            />
            <div class="v-spacer-50" />
            <div class="error-container" v-if="respErr" role="alert" id="missing-selected-option">
              <div class="image-container">
                <img
                  srcset="
                    @/assets/images/error-msg-icon.png    1x,
                    @/assets/images/error-msg-icon-2x.png 2x
                  "
                  src="@/assets/images/error-msg-icon.png"
                  title="error message"
                />
              </div>
              <div class="error-message">
                {{ errMsg }}
              </div>
            </div>

            <div class="button-container">
              <button type="button" class="back-button" :disabled="processing" @click="goBack">
                <img
                  srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                  src="@/assets/images/backIcon.png"
                  title="go back"
                />
              </button>
              <button
                :disabled="processing"
                :class="processing ? 'processing-button' : 'next-button'"
                type="submit"
              >
                {{ processing ? "PROCESSING..." : "NEXT" }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"
import jquery from "jquery"
import helpers from "@/app/helpers"
import funding_api from "@/app/funding_api"
import LabeledCheckbox from "@/components/Inputs/LabeledCheckbox.vue"

export default {
  name: "ExternalTransfer",
  components: {
    FormContainer,
    Splash,
    LabeledCheckbox,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      missingRequest: false,
      chosenAccount: "",
      respErr: false,
      errMsg: "We were unable to process your transfer due to system error. Please try again.",
      missingAgreement: false,
    }
  },
  created() {},
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("fundnow", [
      "setCustomerData",
      "setAccounts",
      "setExtSelectedAccount",
      "setCustomerAccounts",
      "setFundingID",
      "setJwtToken",
      "setReadAgreement",
    ]),
    missingAgreementEvent(e, v) {
      this.missingAgreement = v
    },

    async submitPage() {
      // If the user decides to add another account, redirect them
      if (this.chosenAccount === "new") {
        this.validatePage()
        // if agreement is answered
        if (this.read_agreement) this.$router.push("/external/new")
      } else {
        // loop through accounts and match the chosen account to store it's account object.
        for (let i = 0; i < this.accounts.length; i++) {
          if (this.chosenAccount === this.accounts[i].internal_acc_id) {
            this.selected_account = this.accounts[i]
            this.customer_data.selected_external_account = this.selected_account
          }
        }
        this.validatePage()
        if (!this.missingAgreement && !this.missingSelectedAccount) {
          let fundings = {}
          // create map for accounts
          for (let account of this.customer_data.opened_accounts) {
            fundings[account.id] = helpers.formatMoneyFromString(account.deposit_amount)
          }
          const payload = {
            account_id_funding: fundings,
            transfer_type: "EXTERNAL",
            linked_external_account: true,
            linked_external_internal_account_id: this.selected_account.internal_acc_id,
            account_type: "",
            routing_number: "",
            account_number: "",
            save_account_for_future_transfers: false,
            agreement_accepted: this.read_agreement,
          }
          this.postFundingInit(payload)
        }
      }
    },
    /* Validate account fields and request fields */
    validatePage() {
      this.validateAccounts()
      this.validateAgreement()
    },
    validateAgreement() {
      if (this.read_agreement) {
        this.missingAgreement = false
      } else {
        this.missingAgreement = true
      }
    },
    async postFundingInit(payload) {
      this.processing = true
      this.respErr = false
      const resp = await funding_api.postFundingInit(this.funding_app_id, this.jwtToken, payload)
      this.processing = false
      if (resp.status == 200) {
        // update maturity date
        for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
          for (let j = 0; j < resp.data.response.accounts.length; j++) {
            if (
              this.customer_data.opened_accounts[i].account_number ==
              resp.data.response.accounts[j].account_number
            ) {
              this.customer_data.opened_accounts[i].maturity_date =
                resp.data.response.accounts[j].maturity_date
            }
          }
        }
        this.$router.push("/external/confirm-account")
      } else if (resp.status == 500) {
        this.respErr = true
      } else {
        // general error page
        this.$router.push("/external/something-went-wrong")
      }
    },
    /* check if validate fields are empty */
    validateAccounts() {
      if (this.selected_account === "") {
        this.missingSelectedAccount = true
      } else {
        this.missingSelectedAccount = false
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    maskAccounts(account) {
      const account_num = account.replace(/\d(?=\d{4})/g, "#")
      return account_num
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getCustomerData",
      "getAccounts",
      "getExtSelectedAccount",
      "getCustomerAccounts",
      "getTotal",
      "getAccountType",
      "getRoutingNum",
      "getAccountNum",
      "getAccountNickname",
      "getFundingID",
      "getJwtToken",
      "getReadAgreement",
    ]),
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
    accounts: {
      get() {
        return this.getCustomerData.matched_accounts
      },
    },
    selected_account: {
      get() {
        return this.getExtSelectedAccount
      },
      set(v) {
        this.setExtSelectedAccount(v)
      },
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal)
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      },
    },
    funding_app_id: {
      get() {
        return this.getFundingID
      },
      set(v) {
        this.setFundingID(v)
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken
      },
      set(v) {
        this.setJwtToken(v)
      },
    },
    read_agreement: {
      get() {
        return this.getReadAgreement
      },
      set(v) {
        this.setReadAgreement(v)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
    margin: 0 2px;
  }
}

.radio-line {
  display: flex;
  margin: 30px 15px;
  input,
  label,
  div {
    justify-self: center;
    vertical-align: middle;
    word-wrap: break-word;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 30px 0px;
  }
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error-container {
  min-height: 50px;
  height: max-content;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  .image-container {
    height: 100%;
  }
}
.image-container {
  position: absolute;
  min-width: 40px;
  min-height: 48px;
  height: 100%;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 46px;
  color: $red;
  width: max-content;
}
</style>
