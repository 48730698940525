<!-- Path: /initial-deposit -->
<template>
  <div class="page">
    <SplashBackground />
    <div class="center">
      <FormContainer header="Fund Your Account">
        <!-- use slot -->
        <template #form-content>
          <template v-if="initAPIError || getAPIError || noAccounts">
            <div role="alert" class="error-container">
              <div class="image-container">
                <img
                  class="form-error-icon sys-err-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  aria-hidden="true"
                />
              </div>
              <div class="error-message">
                {{ errorMessage }}
              </div>
            </div>
            <div class="v-spacer-30" />
          </template>
          <form @submit.prevent="submitPage">
            <template v-if="!loading">
              <div class="section-header">
                What amount do you want to deposit into your new accounts?
              </div>
              <div class="v-spacer-20" />
              <div
                class="form-content-container"
                v-for="(account, index) in customer_data.opened_accounts"
                :key="index"
              >
                <!-- row 1 -->
                <div class="label-section">
                  <label for="product-amount-input">
                    {{ formatProductType(account.product_type) }}
                    {{ account.account_number }}
                    <strong>
                      <sup class="fhb-red">*</sup>
                    </strong>
                  </label>
                  <div>
                    <i
                      >Min {{ minMaxFormatting(account.minimum) }}
                      <span>Max {{ minMaxFormatting(account.max) }}</span></i
                    >
                  </div>
                </div>
                <div class="input-section">
                  <MoneyInput
                    :aria-describedby="`${account.account_type}-amount-input-err`"
                    :name="`${account.account_type}-amount-input`"
                    aria-required="true"
                    :disabled="processing"
                    id="product-amount-input"
                    v-model="account.deposit_amount"
                    :class="
                      missingProductAmount[index] || invalidProductAmount[index]
                        ? 'll-textfield-error'
                        : 'll-textfield flex-input'
                    "
                    v-on:blur="validateProductAmount(`${account.account_type}-amount-input`, index)"
                    data-test="product-amount"
                    required
                  />
                  <!-- CD info -->
                  <div class="cd-info" v-if="account.account_type.toUpperCase().includes('CD')">
                    Your eCD will mature in {{ account.cd_term }}
                    {{ account.term_indicator == "M" ? "months" : "years" }}.
                  </div>
                  <!-- Error message -->
                  <div
                    class="error"
                    role="alert"
                    :id="`${account.account_type}-amount-input-err`"
                    v-if="missingProductAmount[index] || invalidProductAmount[index]"
                  >
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="error"
                    />
                    <div v-if="missingProductAmount[index]">Please enter a value</div>
                    <div v-if="invalidProductAmount[index] && errorMax">
                      Enter a value between ${{ account.minimum }} and ${{ errorMax }}
                    </div>
                    <div v-if="invalidProductAmount[index] && !errorMax">
                      Enter a value greater than ${{ account.minimum }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="loader-container">
                <img
                  class="loader"
                  srcset="
                    @/assets/images/fhb-logo-load-reverse.gif 1x,
                    @/assets/images/fhb-logo-load-reverse.gif 2x
                  "
                  src="@/assets/images/fhb-logo-load-reverse.gif"
                  title="loader"
                />
              </div>
            </template>
            <!-- divider -->
            <div class="divider form-content-container">
              <div class="total-header">Total Deposit Amount</div>
              <div class="total">
                <div id="amount">{{ moneyToString(total) }}</div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <div class="section-header">
              How do you want to make your initial request?
              <strong>
                <sup class="fhb-red">*</sup>
              </strong>
            </div>
            <div class="v-spacer-20" />
            <div class="section-2">
              <!-- internal - only shown if the customer is existing -->
              <div
                v-if="customer_data.primary_existing && hasInternalAccounts"
                id="internal"
                class="radio-ctn"
              >
                <div class="radio-line">
                  <input
                    name="radio-fhb"
                    id="radio-fhb"
                    type="radio"
                    value="INTERNAL"
                    :disabled="processing"
                    v-model="transferFrom"
                  />
                  <label for="radio-fhb">Transfer from my other FHB account</label>
                </div>
                <div class="infobox">
                  Up to the current available balance of an eligible FHB account.
                </div>
              </div>
              <!-- external -->
              <div id="external" class="radio-ctn">
                <div class="radio-line">
                  <input
                    name="radio-external"
                    id="radio-external"
                    type="radio"
                    value="EXTERNAL"
                    :disabled="processing || total > external_max"
                    v-model="transferFrom"
                  />
                  <label for="radio-external">Transfer from my account at another bank </label>
                </div>
                <div class="infobox">
                  You can deposit up to $250,000 from your account at another bank.
                </div>
              </div>
              <!-- check -->
              <div id="check" class="radio-ctn">
                <div class="radio-line">
                  <input
                    name="radio-check"
                    id="radio-check"
                    type="radio"
                    value="CHECK"
                    :disabled="processing"
                    v-model="transferFrom"
                  />
                  <label for="radio-check">Check</label>
                </div>
                <div class="infobox">
                  A hold may be placed on your check deposit.
                  <ul>
                    <li>Up to $20,000 for mobile check deposit (not available for CD accounts).</li>
                    <li>Mailed in checks can be for any dollar amount.</li>
                  </ul>
                </div>
              </div>
              <div class="error" v-if="missingMethod">
                <img
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  title="missing request"
                />
                Please select a transfer method
              </div>
            </div>
            <div class="button-container">
              <button
                type="submit"
                :class="processing ? 'processing-button' : 'next-button'"
                title="next button"
                :disabled="processing"
              >
                {{ nextButton }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
      <InitialDepositRetry v-if="retryModal" @close="closeModal" @retry="retryCall" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormContainer from "@/components/FormContainer.vue";
import MoneyInput from "@/components/Inputs/MoneyInput.vue";
import SplashBackground from "@/components/Splash.vue";
import jQuery from "jquery";
import funding_api from "@/app/funding_api";
import constants from "@/app/constants";
import InitialDepositRetry from "@/components/modals/InitialDepositRetry.vue";
import helpers from "@/app/helpers.js";

export default {
  name: "InitialDeposit",
  components: {
    FormContainer,
    MoneyInput,
    SplashBackground,
    InitialDepositRetry,
  },
  data() {
    return {
      loading: false,
      invalidProductAmount: [],
      missingProductAmount: [],
      processing: false,
      notEditing: false,
      toggleInfo: false,
      next: false,
      missingMethod: false,
      errCnt: [],
      redirect_id: "",
      initAPIError: false,
      getAPIError: false,
      noAccounts: false,
      errorMessage: "",
      nextButton: "NEXT",
      errorMax: 0,
      external_max: constants.external_transfer_max,
      retryModal: false,
      numOfRetries: 0,
    };
  },
  methods: {
    ...mapActions("fundnow", [
      "setFromFHB",
      "setFromOther",
      "setFromCheck",
      "setManuallyFundedAccounts",
      "setCustomerData",
      "setTotal",
      "setTransferFrom",
      "setFundingID",
      "setCustomerType",
      "setHasLinkedExternalAccounts",
      "setJwtToken",
      "setHasInternal",
      "setHasValidInternal",
    ]),

    // submit
    async submitPage() {
      await this.validatePage();
      // if internal or check is selected, call post /init`
      if (this.transferFrom === "INTERNAL" && this.next) {
        await this.postFundingInit();
      } else if (this.transferFrom === "CHECK" && this.next) {
        this.$router.push("/check/fund-by-check");
      }
      // otherwise proceed to external flow
      this.goToNextPage();
    },
    // function to make api call to get customer opened accounts on created
    async getFundingInfo() {
      this.getAPIError = false;
      this.loading = true;
      const resp = await funding_api.getFundingAccounts(this.redirect_id);
      this.loading = false;

      // grab customer data and accounts
      if (resp) {
        if (resp.status === 200) {
          this.customer_data = resp.data.response;
          this.jwtToken = resp.data.response.jwt_token;
          this.hasExternalAccounts = resp.data.response.matched_accounts ? true : false;
          // user has internal personal accounts, but at this point does not mean accounts have enough for funding
          this.hasInternalAccounts = resp.data.response.has_internal_accounts;
          this.funding_app_id = resp.data.response.id;
          if (this.customer_data.opened_accounts) {
            for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
              this.customer_data.opened_accounts[i].apy = helpers.removeLeadingZeros(
                this.customer_data.opened_accounts[i].apy
              );
            }
          } else if (resp.data.response.manually_funded_accounts?.length > 0) {
            // set in store
            this.setManuallyFundedAccounts(resp.data.response.manually_funded_accounts);
            // push to completed
            this.$router.push("/accounts-funded-manually");
            return;
          }
        } else if (resp.status == 403 && resp.data?.response?.accounts_closed) {
          this.$router.push("/accounts-closed");
        } else if (resp.status == 403 && resp.data?.response?.expired === true) {
          this.$router.push("/return-expired");
        } else if (resp.status === 308) {
          this.$router.push("/return/access-application/" + resp.data.response.id);
        } else if (resp.status === 500 || resp.status === 502) {
          // if 500/502, show retry modal
          this.retryModal = true;
        } else if (resp.status == 404) {
          // redirect id is invalid
          this.$router.push("/application-not-found");
        }
      }
    },
    closeModal() {
      this.retryModal = false;
    },
    // Allows another retry to load in opened accounts.
    // if failed three times, redirect them to error page
    retryCall() {
      this.getFundingInfo();
      this.numOfRetries++;
      this.retryModal = false;
      if (this.numOfRetries >= 2) {
        this.$router.push("/error-loading-accounts");
      }
    },
    // Call to be made if Internal and Check is selected. This call will occur on the next page if external is selected.
    async postFundingInit() {
      this.initAPIError = false;
      let fundings = {};
      // create map for accounts
      for (let account of this.customer_data.opened_accounts) {
        fundings[account.id] = helpers.formatMoneyFromString(account.deposit_amount);
      }
      const payload = {
        account_id_funding: fundings,
        transfer_type: this.transferFrom,
        linked_external_account: false,
        linked_external_internal_id: "",
        account_type: "",
        routing_number: "",
        account_number: "",
        save_account_for_future_transfers: false,
        agreement_accepted: false,
      };
      this.processing = true;
      this.nextButton = "PROCESSING...";
      const resp = await funding_api.postFundingInit(this.funding_app_id, this.jwtToken, payload);
      if (resp) {
        if (resp.status === 200) {
          if (this.transferFrom == "INTERNAL") {
            // update maturity date
            for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
              for (let j = 0; j < resp.data.response.accounts.length; j++) {
                if (
                  this.customer_data.opened_accounts[i].account_number ==
                  resp.data.response.accounts[j].account_number
                ) {
                  this.customer_data.opened_accounts[i].maturity_date =
                    resp.data.response.accounts[j].maturity_date;
                }
              }
            }
            // Only have to check accounts if internal. Check routes don't need to check for accounts.
            this.hasValidInternalAccounts = false;
            // if has_accounts is false, then no internal accounts had enough funds for transfer
            if (resp.data.response.has_accounts) {
              this.hasValidInternalAccounts = true;
              if (resp.data.response.available_accounts) {
                this.customer_data.internal_accounts = resp.data.response.available_accounts;
              }
              if (resp.data.response.matched_accounts) {
                this.customer_data.external_accounts = resp.data.response.matched_accounts;
              }
            }
          }
        } else if (resp.status === 422) {
          this.initAPIError = true;
          this.errorMessage = "Something went wrong. Please try again later.";
        } else {
          this.initAPIError = true;
          this.errorMessage = "Something went wrong. Please try again later.";
        }
      }
    },

    goToNextPage() {
      this.processing = false;
      this.nextButton = "NEXT";

      // if no form errors or api error from getting opened accounts
      if (this.next && !this.getAPIError) {
        // if internal accounts exist and dda exists and not error from init call
        if (this.transferFrom == "INTERNAL" && !this.initAPIError) {
          // must have internal accounts and accounts have to be valid (have enough funds for transfer)
          if (this.hasInternalAccounts && this.hasValidInternalAccounts) {
            this.$router.push("/internal/transfer-from-account");
          } else {
            // if no internal accounts exist and no init error call (if no transferable internal accounts exist)
            this.noAccounts = true;
            this.errorMessage =
              "We could not find any eligible FHB accounts to transfer from. Your other FHB accounts may not have the funds available for the total deposit amount, or your account may not be eligible to transfer funds into a newly opened account. Please select a different transfer method.";
          }
        }
        // If external, just check if external there are linked external accounts
        if (this.transferFrom == "EXTERNAL") {
          if (this.hasExternalAccounts) {
            // Check to see if total is less than the external max
            if (this.total < this.external_max) {
              // proceed to transfer
              this.$router.push("/external/transfer-account");
            } else {
              // select another method
            }
          } else {
            // proceed to new account linking
            this.$router.push("/external/new");
          }
        }
        if (this.transferFrom == "CHECK" && !this.initAPIError) {
          this.$router.push("/check/fund-by-check");
        }
      }
    },
    /* Validate account fields and request fields */
    async validatePage() {
      // check for errors only if customer_data object exists.
      if (Object.keys(this.customer_data).length > 0) {
        await this.checkErrors();

        this.$nextTick(() => {
          this.errCnt = jQuery(".error").length;
          // check if any invalid or missing fields
          if (this.errCnt === 0) {
            this.next = true;
          } else if (this.errCnt === 1) {
            this.next = false;
          } else {
            this.next = false;
          }
        });
      }
    },
    // validate product amount
    validateProductAmount(name, i) {
      // reset error vars
      this.missingProductAmount[i] = false;
      this.invalidProductAmount[i] = false;
      var product = document.getElementsByName(name)[0];
      const amount = this.customer_data.opened_accounts[i].deposit_amount
        ? helpers.formatMoneyFromString(this.customer_data.opened_accounts[i].deposit_amount)
        : null;
      this.missingProductAmount[i] = amount ? false : true;

      if (this.missingProductAmount[i]) {
        product.setAttribute("aria-invalid", "true");
      } else if (!this.missingProductAmount[i]) {
        // if the transfer max is 0, then there is no max for this product (internal should have no max unless product is CD)
        if (!this.customer_data.opened_accounts[i].max) {
          // only check if amount is over the minimum
          this.invalidProductAmount[i] = amount < this.customer_data.opened_accounts[i].minimum;
          if (this.invalidProductAmount[i]) {
            product.setAttribute("aria-invalid", "true");
          }
        }
        // if there is a max, check if amount is within range
        else if (
          amount > this.customer_data.opened_accounts[i].max ||
          amount < this.customer_data.opened_accounts[i].minimum
        ) {
          this.invalidProductAmount[i] = true;
          this.errorMax = this.customer_data.opened_accounts[i].max;
          product.setAttribute("aria-invalid", "true");
        }
      } else {
        product.setAttribute("aria-invalid", "false");
      }
      // update total
      if (
        this.customer_data.opened_accounts[i].is_tiered_rate &&
        this.customer_data.opened_accounts[i].is_cd
      ) {
        this.setTieredAPY(amount, i);
        this.setTieredInterest(amount, i);
      }
      this.totalDeposit();
    },
    /* sum up and return total */
    moneyToString(val) {
      if (!val) return "$0.00";
      return helpers.formatMoneyToString(val);
    },
    totalDeposit() {
      let sum = 0;
      for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
        if (this.customer_data.opened_accounts[i].deposit_amount) {
          sum += helpers.formatMoneyFromString(
            this.customer_data.opened_accounts[i].deposit_amount
          );
        }
      }
      this.total = sum;
    },
    // toggle infobox for external transfer option
    toggleInfobox() {
      this.toggleInfo = !this.toggleInfo;
    },
    // function that returns a promise due to async loop
    // loop through customer accounts and validate deposit amount for each product.
    checkErrors() {
      return new Promise((resolve) => {
        this.missingMethod = false;
        for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
          this.validateProductAmount(
            `${this.customer_data.opened_accounts[i].account_type}-amount-input`,
            i
          );
        }
        // if a transfer method is selected
        if (this.transferFrom) {
          resolve();
        } else {
          this.next = false;
          this.missingMethod = true; // if transfer method is missing
        }
        resolve();
      });
    },
    formatProductType(product_type) {
      return constants.ProductTypeDictionary[product_type];
    },
    formatMoney(v) {
      return helpers.formatMoneyToString(v);
    },
    minMaxFormatting(value) {
      return helpers.formatMinMax(value);
    },
    // set apy depending on the deposit amount entered by customer
    setTieredAPY(deposit, index) {
      // initially deposit amount is empty, so need to set to 0 so that we can grab the first tiered rate apy value
      for (let i = 0; i < this.customer_data.opened_accounts[index].tiered_rates.length; i++) {
        if (deposit > this.customer_data.opened_accounts[index].tiered_rates[i].bal_float) {
          continue;
        } else {
          // set interest rate so later pages will contain the same interest
          this.customer_data.opened_accounts[index].apy = parseFloat(
            this.customer_data.opened_accounts[index].tiered_rates[i].calculated_apy
          ).toFixed(2);
          return;
        }
      }
    },
    // set interest rate depending on the deposit amount entered by customer
    setTieredInterest(deposit, index) {
      for (let i = 0; i < this.customer_data.opened_accounts[index].tiered_rates.length; i++) {
        if (deposit > this.customer_data.opened_accounts[index].tiered_rates[i].bal_float) {
          continue;
        } else {
          // set interest rate so later pages will contain the same interest
          this.customer_data.opened_accounts[index].interest_rate = parseFloat(
            this.customer_data.opened_accounts[index].tiered_rates[i].rte
          ).toFixed(2);
          return;
        }
      }
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getFromFHB",
      "getFromOther",
      "getFromCheck",
      "getFundingID",
      "getCustomerData",
      "getTotal",
      "getTransferFrom",
      "getHasLinkedExternalAccounts",
      "getJwtToken",
      "getHasInternal",
      "getHasValidInternal",
    ]),
    customer_data: {
      get() {
        return this.getCustomerData;
      },
      set(v) {
        this.setCustomerData(v);
      },
    },
    opened_accounts: {
      get() {
        return this.getCustomerData.opened_accounts;
      },
    },

    total: {
      get() {
        return this.getTotal;
      },
      set(v) {
        this.setTotal(v);
      },
    },
    transferFrom: {
      get() {
        return this.getTransferFrom;
      },
      set(v) {
        this.setTransferFrom(v);
      },
    },
    funding_app_id: {
      get() {
        return this.getFundingID;
      },
      set(v) {
        this.setFundingID(v);
      },
    },
    hasExternalAccounts: {
      get() {
        return this.getHasLinkedExternalAccounts;
      },
      set(v) {
        this.setHasLinkedExternalAccounts(v);
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken;
      },
      set(v) {
        this.setJwtToken(v);
      },
    },
    hasInternalAccounts: {
      get() {
        return this.getHasInternal;
      },
      set(v) {
        this.setHasInternal(v);
      },
    },
    hasValidInternalAccounts: {
      get() {
        return this.getHasValidInternal;
      },
      set(v) {
        this.setHasValidInternal(v);
      },
    },
  },
  async created() {
    // If coming from fund new account, then call to grab customer data was already made so no need to call again.
    // otherwise, make call to grab customer data

    this.redirect_id = this.$route.query.redirect_id;
    // only call if no customer data and redirect_id is present (temp)
    if (Object.keys(this.customer_data).length === 0 && this.redirect_id) {
      await this.getFundingInfo();
    }
  },
};
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.label-section {
  max-width: 346px;
  height: 65px;
  padding: 11px, 0px, 0px, 0px;
  gap: 4px;
  display: inline-block;
  margin: 20px 0;
  @include media-breakpoint-down(sm) {
    margin: 0 0;
    padding: 11px, 0px, 0px, 0px;
    height: 45px;
  }
}
.input-section {
  max-width: 300px;
  height: 75px;
  gap: 4px;
  margin: 20px 0;
  @include media-breakpoint-down(sm) {
    margin: 0px 0px;
    padding: 0px;
    height: 45px;
  }
}
.form-content-container {
  margin: 25px 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.divider {
  position: relative;
  border-top: solid 1px black;
  padding: 20px 0;
  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 100px;
  }
}
.total {
  width: 100%;
  margin-left: 14px;
  div {
    display: inline-block;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 0px;
  }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type="radio"] {
  max-width: 25px;
  max-height: 25px;
  width: 100%;
  margin: 0 8px;
  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
    margin: auto 2px;
  }
}
.radio-line {
  display: flex;
  margin: 5px 15px;
  @include media-breakpoint-down(sm) {
    margin: 5px 0;
  }
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.infobox {
  max-width: 599.1px;

  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  margin-left: 24px;
  ul {
    margin-top: -1px;
    padding: 0px 20px;
  }
  @include media-breakpoint-up(sm) {
    margin-left: 60px;
  }
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 1.5;
  margin-top: 2px;
  height: auto;
  img {
    width: 15px;
    height: 15px;
    margin-top: 5px;
  }
}
.radio-ctn {
  margin: 15px 0;
}
.total-header {
  word-wrap: break-word;
  word-break: break-all;
  @include media-breakpoint-down(sm) {
    font-style: 10px;
  }
}
.cd-info {
  height: auto;
}
.maintenence-msg {
  font-size: 16px;
  font-weight: 450;
  line-height: 1.5;
  border: solid 2px $red;
  background-color: $white;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}
.error-container {
  min-height: 50px;
  height: max-content;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  .image-container {
    height: 100%;
  }
}
.image-container {
  position: absolute;
  min-width: 40px;
  min-height: 48px;
  height: 100%;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    margin: 0 auto;
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 46px;
  color: $red;
  width: max-content;
}
</style>
