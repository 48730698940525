<!-- Path: /external/realtime-authentication-login -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Verify your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
              <img
                srcset="
                  @/assets/images/account_created.png    1x,
                  @/assets/images/account_created-2x.png 2x
                "
                src="@/assets/images/account_created.png"
                title="Accounts created"
              />
            </div>
            <!-- for login credentials -->
            <div>Transfer from my account at another bank</div>
          </div>

          <div class="v-spacer-40" />
          <div v-if="oauth_verification && oauth_url != ''">
            <div class="sub-header">
              <div id="enter-credentials">Please sign in to your account</div>
              <div id="note">
                A new window will open to {{ realtime_resp.fi_name }}. Please sign in to your
                {{ realtime_resp.fi_name }} account, then press Continue when prompted.
              </div>
            </div>
          </div>
          <div v-else>
            <div class="sub-header">
              <div id="enter-credentials">Enter your credentials</div>
              <div id="note">
                Provide your credentials to enable First Hawaiian Bank to retrieve your financial
                data from {{ realtime_resp.fi_name }}. This may take a few minutes.
              </div>
              <div id="note">
                We will never save your {{ realtime_resp.fi_name }} login information, this is only
                used to verify your account information.
              </div>
            </div>
            <div class="v-spacer-20" />
            <form>
              <div class="realtime-resp-ctn">
                <!-- loop through params array and display first two params, which should be login credentials (at this point there should only be two params)-->
                <div
                  v-for="(param, index) in realtime_resp.external_login_params
                    .FILoginParametersInfo"
                  :key="index"
                >
                  <UserID
                    v-if="param.CredentialsParamType == 'login'"
                    :label="param.CredentialsCaption"
                    :id="`param-${index}-${param.CredentialsCaption}`"
                    :ariaDescribe="`${param.CredentialsCaption}-err`"
                    :name="`name-1-${param.CredentialsCaption}`"
                    :test="`test-1-${param.CredentialsCaption}`"
                    :required="true"
                    v-model="param.value"
                    :missing="missingCredentials[index]"
                    @blur="validateField(index, param.CredentialsCaption)"
                  />
                  <Password
                    v-if="param.CredentialsParamType == 'password'"
                    :label="param.CredentialsCaption"
                    :id="`param-${index}-${param.CredentialsCaption}`"
                    :ariaDescribe="`${param.CredentialsCaption}-err`"
                    :name="`name-1-${param.CredentialsCaption}`"
                    :test="`test-1-${param.CredentialsCaption}`"
                    :required="true"
                    v-model="param.value"
                    :missing="missingCredentials[index]"
                    @blur="validateField(index, param.CredentialsCaption)"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="v-spacer-20" />
          <!-- Error message -->
          <div v-if="loginErr && realtime_failure_desc" class="error-message" role="alert">
            {{ realtime_failure_desc }}
          </div>
          <div v-else-if="loginErr" class="error-message" role="alert">
            An error occurred, please try again.
          </div>
          <div v-if="displayOAuthPendingMessage" class="error-message" role="alert">
            We were not able to verify your account, please try again. If you closed the window or
            the window did not open,
            <a @click="submitRetry" style="box-sizing: inherit" href="#">click here</a>
          </div>
          <div v-if="displayOAuthRetryMessage" class="error-message" role="alert">
            We were not able to verify your account due to a system error. We apologize for any
            inconvenience this may have caused, please
            <a @click="submitRetry" style="box-sizing: inherit" href="#">try again</a>
          </div>
          <div class="v-spacer-20" />
          <div class="button-container">
            <button type="button" class="back-button" @click="goBack" title="go back button">
              <img
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon.png"
                title="go back"
              />
            </button>
            <button
              v-if="displayOAuthRetryMessage || displayOAuthPendingMessage"
              type="button"
              :class="processing ? 'processing-button' : 'login-btn no-print'"
              @click="submitRetry"
            >
              RETRY
            </button>
            <button
              v-else
              type="button"
              :class="processing ? 'processing-button' : 'login-btn no-print'"
              @click="submit"
            >
              {{ processing ? "PROCESSING..." : "CONTINUE" }}
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"
import env from "@/app/env"
import jquery from "jquery"
import UserID from "@/components/Inputs/UserID.vue"
import Password from "@/components/Inputs/Password.vue"
import funding_api from "@/app/funding_api"

export default {
  name: "RealtimeAuthenticationLogin",
  components: {
    FormContainer,
    Splash,
    UserID,
    Password,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      account: "",
      loginURL: env.loginURL(),
      login: "",
      password: "",
      loginErr: false,
      missingCredentials: [false, false],
      displayOAuthPendingMessage: false,
      displayOAuthRetryMessage: false,
      realtime_failure_desc: "",
    }
  },
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
    if (this.oauth_verification && this.oauth_url != "") {
      window.open(this.oauth_url, "_blank")
    }
  },
  methods: {
    ...mapActions("fundnow", [
      "setRealtimeResp",
      "setFundingID",
      "setRealtimeVerifyResp",
      "setRealtimeParams",
      "setOAuthVerification",
      "setOAuthMethod",
      "setOAuthURL",
    ]),
    submitRetry() {
      this.displayOAuthPendingMessage = false
      this.displayOAuthRetryMessage = false
      window.open(this.oauth_url, "_blank").focus()
    },
    async submit() {
      await this.checkErrors()
      if (this.next) {
        await this.postRealtimeData()
      }
    },
    async postRealtimeData() {
      this.loginErr = false
      // create payload and set from_start to true
      let payload = {
        from_start: true,
        params: [],
      }

      // If not running OAuth, setup the login params
      if (!(this.oauth_verification && this.oauth_url != "")) {
        // loop through the param info
        for (let param in this.realtime_resp.external_login_params.FILoginParametersInfo) {
          // for login and password only, so only first two params
          if (param == 0 || param == 1) {
            payload.params.push({
              param_id:
                this.realtime_resp.external_login_params.FILoginParametersInfo[param]
                  .CredentialsParamId,
              crypt_param_nv: {
                param_id: param.CredentialsParamId,
                crypt_param_val: {
                  crypt_type: "none",
                  crypt_val:
                    this.realtime_resp.external_login_params.FILoginParametersInfo[param].value,
                },
              },
            })
          }
        }
      }
      this.processing = true
      this.realtime_failure_desc = ""
      const resp = await funding_api.postRealtimeData(this.app_id, this.jwtToken, payload)
      this.processing = false

      if (resp.status == 200) {
        // If we're in OAuth
        if (resp.data.response.oauth_status == "Approved") {
          this.$router.push("/external/realtime-confirm-account")
        } else if (resp.data.response.oauth_status == "Pending") {
          this.displayOAuthPendingMessage = true
        } else if (resp.data.response.oauth_status == "Retry") {
          // Display retry
          this.displayOAuthRetryMessage = true
          // if Retry, change oauthURL
          this.oauth_url = resp.data.response.oauth_url
        } else if (resp.data.response.oauth_status == "Locked") {
          this.$router.push("/external/account-verification-failed")
        }
        // OAuth only expects Approved or Unknown. If an undocumented result occurs, Unknown is returned
        else if (resp.data.response.oauth_status == "Unknown") {
          this.$router.push("/external/account-verification-failed")
        }
        // if verification status is "Requires Approval" then continue with flow.
        else if (resp.data.response.VerificationStatus == "Requires Approval") {
          // if params are present, then continue with flow.
          if (resp.data.response.TwoFARealtimeAcctVerificationParam.Param) {
            this.realtime_verify_resp = resp.data.response
            this.$router.push("/external/realtime-authentication")
          } else {
            // login may have failed
            this.loginErr = true
            this.realtime_failure_desc = resp.data.response.FailureReasonDesc
              ? resp.data.response.FailureReasonDesc
              : ""
          }
        }
        // if suspended or denied, redirect user to failed page
        else if (
          resp.data.response.VerificationStatus == "Suspended" ||
          resp.data.response.VerificationStatus == "Denied"
        ) {
          this.$router.push("/external/realtime-unsuccessful")
        }
        // if verfication is passed without need to do 2fa or security questions
        else if (
          resp.data.response.VerificationStatus == "Approved" ||
          resp.data.response.VerificationStatus == "Pass"
        ) {
          if (!resp.data.response.TwoFARealtimeAcctVerificationParam.Param) {
            this.$router.push("/external/realtime-confirm-account")
          }
        } else {
          // if not "Requires Approval" send to error page
          this.$router.push("/external/account-verification-failed")
        }
      } else if (resp.status !== 500) {
        this.$router.push("/external/account-verification-failed")
      } else {
        this.loginErr = true
      }
    },
    // validate the field displayed
    validateField(index, caption) {
      const field = jquery(`#param-${index}-${caption}`)
      this.missingCredentials[index] = this.realtime_resp.external_login_params
        .FILoginParametersInfo[index].value
        ? false
        : true

      if (this.missingCredentials[index]) {
        field.attr("aria-invalid", "true")
      } else {
        field.attr("aria-invalid", "false")
      }
    },
    // returns a promise that loops and validates fields
    validatePage() {
      return new Promise(resolve => {
        if (this.oauth_verification && this.oauth_url != "") {
          resolve()
        }
        for (
          let i = 0;
          i < this.realtime_resp.external_login_params.FILoginParametersInfo.length;
          i++
        ) {
          this.validateField(
            i,
            this.realtime_resp.external_login_params.FILoginParametersInfo[i].CredentialsCaption
          )
        }
        resolve()
      })
    },
    // checks for errors on the page
    async checkErrors() {
      await this.validatePage()
      this.$nextTick(() => {
        this.errCnt = jquery(".error").length
        // check if any invalid or missing fields
        if (this.errCnt === 0) {
          this.next = true
        } else if (this.errCnt === 1) {
          this.next = false
        } else {
          this.next = false
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getExtSelectedAccount",
      "getCustomerData",
      "getTotal",
      "getRealtimeResp",
      "getFundingID",
      "getJwtToken",
      "getRealtimeVerifyResp",
      "getRealtimeParams",
      "getOAuthVerification",
      "getOAuthMethod",
      "getOAuthURL",
    ]),
    realtime_resp: {
      get() {
        return this.getRealtimeResp
      },
      set(v) {
        return this.setRealtimeResp(v)
      },
    },
    realtime_verify_resp: {
      get() {
        return this.getRealtimeVerifyResp
      },
      set(v) {
        return this.setRealtimeVerifyResp(v)
      },
    },
    realtime_params: {
      get() {
        return this.getRealtimeParams
      },
      set(v) {
        return this.setRealtimeParams(v)
      },
    },
    app_id: {
      get() {
        return this.getFundingID
      },
      set(v) {
        this.setFundingID(v)
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken
      },
    },
    oauth_verification: {
      get() {
        return this.getOAuthVerification
      },
      set(v) {
        this.setOAuthVerification(v)
      },
    },
    oauth_method: {
      get() {
        return this.getOAuthMethod
      },
      set(v) {
        this.setOAuthMethod(v)
      },
    },
    oauth_url: {
      get() {
        return this.getOAuthURL
      },
      set(v) {
        this.setOAuthURL(v)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.grid-table {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;
  border-bottom: solid 1px $gray;

  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}
.grid-table-total {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 15px;

  #headers {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  .left-col {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  .right-col {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}
.table-spacing {
  margin-top: 20px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid $yellowLight;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $yellowHover;
  }
}
.cd-info {
  font-weight: normal;
  font-size: 15px;
}
.realtime-resp.ctn {
  display: flex;
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.sub-header {
  text-align: center;
  #enter-credentials {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.011em;
    text-align: center;
  }

  #id {
    width: 600px;
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: center;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error-message {
  color: $red;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
}
</style>
