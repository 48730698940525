<!-- Path: /external/trial-deposits-verify -->
<template>
  <div class="page">
    <SplashBackground />
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">For your protection, you must verify your account</div>
          <div class="info-container">
            <div class="v-spacer-20" />
            <div class="verify-desc">
              Please verify your {{ accountInfo.fi_name }} {{ accountInfo.account_type }} x{{
                account_number
              }}
              account by verifying small amounts deposited into your bank account (1-3 business
              days).
            </div>
            <div class="v-spacer-20" />
            <div class="form-header section-header-2">Verifying Deposit Amounts</div>
            <div>
              <ol>
                <li>
                  Click SEND DEPOSITS below and First Hawaiian Bank will deposit two small amounts
                  into your {{ accountInfo.fi_name }} {{ accountInfo.account_type }} x{{
                    account_number
                  }}
                  account.
                </li>
                <div class="v-spacer-30" />
                <li>
                  In the next 1-3 business days, log in to your {{ accountInfo.fi_name }} to
                  identify the two amounts in your transaction history. The transactions will be
                  from First Hawaiian Bank and have “ACCT VERIFY” in the description.
                  <div class="v-spacer-20" />
                </li>
                <div class="v-spacer-20" />
                <li>
                  Check your email for instructions on how to return your application and verify the
                  trial deposit amounts.
                </li>
              </ol>
              <div class="note">
                <strong> Note: </strong>
                First Hawaiian Bank will reclaim the total amount of the two trial deposits. There
                is no cost to you for this service.
              </div>
            </div>
            <div class="v-spacer-50">
              <!-- <div class="error-container" v-if="apiErr">
                <div class="image-container">
                  <img srcset="@/assets/images/error-msg-icon.png 1x, @/assets/images/error-msg-icon-2x.png 2x" src="@/assets/images/error-msg-icon.png" title="error message"/> 
                </div>
                <div class="error-message">
                  {{ apiErrMsg }}
                </div>
              </div>  -->
            </div>

            <div class="v-spacer-30" />
            <!-- if Verify small amounts deposited is selected -->
            <div class="v-spacer-50" />
            <div class="button-container">
              <button type="button" class="back-button" @click="goBack">
                <img
                  srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                  src="@/assets/images/backIcon.png"
                  title="go back icon"
                />
              </button>
              <button
                :class="submitted ? 'sent-button' : 'next-button'"
                @click="submit"
                :disabled="processing"
              >
                <div v-if="!submitted">SEND DEPOSITS</div>
                <div v-else>
                  <img
                    class="check-img"
                    srcset="@/assets/images/check.png 1x, @/assets/images/check-2x.png 2x"
                    src="@/assets/images/check.png"
                    title="sent icon"
                  />
                  SENT
                </div>
              </button>
            </div>
            <div class="v-spacer-20" />
            <div style="text-align: center" v-if="submitted">
              Your trial deposits have been initiated and an email sent.
            </div>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import SplashBackground from "@/components/Splash.vue"
import jquery from "jquery"
import api from "@/app/funding_api"

export default {
  name: "TrialDepositsInit",
  components: {
    FormContainer,
    SplashBackground,
  },
  data() {
    return {
      missingVerifyMethod: false,
      processing: false,
      next: false,
      submitted: false,
    }
  },
  created() {},
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
    console.log("test", this.accountInfo)
  },
  methods: {
    ...mapActions("fundnow", [
      "setAccountType",
      "setRoutingNum",
      "setAccountNum",
      "setAccountNickname",
      "setSaveAccount",
      "setReadAgreement",
      "setLoginAccount",
      "setVerifyAmounts",
      "setVerifyMethod",
      "setExtSelectedAccount",
      "setAccounts",
      "setRealTimeVerification",
      "setRealtimeResp",
      "setJwtToken",
    ]),

    submit() {
      this.submitted = true
      this.startTrialDeposit(this.app_id, this.jwtToken)
    },
    goBack() {
      this.$router.go(-1)
    },

    async startTrialDeposit(funding_id, token) {
      this.processing = true
      const resp = await api.postTrialDepositInit(funding_id, token)
      this.processing = false
      if (resp.status === 200) {
        this.$router.push("/external/deposits-sent")
      } else {
        this.$router.push("/external/cannot-link-external")
      }
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getFundingID",
      "getAccountType",
      "getRoutingNum",
      "getAccountNum",
      "getAccountNickname",
      "getSaveAccount",
      "getReadAgreement",
      "getLoginAccount",
      "verifyAmounts",
      "getVerifyMethod",
      "getExtSelectedAccount",
      "getAccounts",
      "getRealtimeVerification",
      "getRealtimeResp",
      "getJwtToken",
      "getCustomerData",
    ]),
    verify_method: {
      get() {
        return this.getVerifyMethod
      },
      set(v) {
        this.setVerifyMethod(v)
      },
    },
    accountInfo: {
      get() {
        return this.getCustomerData.selected_external_account
      },
    },
    account_number: {
      get() {
        return this.getAccountNum.slice(-4)
      },
    },
    app_id: {
      get() {
        return this.getFundingID
      },
      set(v) {
        this.setFundingID(v)
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken
      },
      set(v) {
        this.setJwtToken(v)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  // display: grid;
  row-gap: 20px;
  margin-top: 10px;
  // grid-template-columns: 50% 50%;
  // @include media-breakpoint-down(sm) {
  //   display: block;
  // }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}

.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.infobox {
  max-width: 599.1px;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  border: 2px solid $grayLight;
  margin-left: 60px;
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.account-nickname-container {
  padding: 10px 20px;
}
.radio-line {
  display: flex;
  margin: 15px;
}
.section-header-2 {
  text-align: left;
}
.table-header {
  font-weight: 700;
  color: $grayDark;
}
table {
  width: 100%;
  text-align: left;
  tr {
    border-bottom: solid 1px $gray;
  }
  td,
  th {
    padding: 5px 10px;
    // font-size: 12px;
    // font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.029em;
  }
}
.note {
  background-color: $orangeFaint;
  padding: 18px;
}
.sent-button {
  color: black;
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
}
.verify-desc {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
}
.info-container {
  padding: 0px 10px;
}
.check-img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
</style>
