<!-- Path: /external/verify-deposits -->
<template>
  <div class="page">
    <SplashBackground />
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">Verify Deposits</div>
          <div class="form-header-sub">Welcome back {{ first_name }}!</div>
          <div role="alert" class="sys-err-msg" v-if="retry">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                aria-hidden="true"
              />Verification failed. Please try again.
            </div>
          </div>
          <form @submit.prevent="submitPage">
            <div class="v-spacer-30" />
            <div class="section-header">
              Please enter the two small deposits that were in your {{ otherBank }}
              {{ external_account_type }} x{{ external_account_number }}.
            </div>
            <div class="v-spacer-20" />
            <div class="form-content-container">
              <!-- row 1 -->
              <div class="label-section">
                <label for="deposit-amount-one">
                  Deposit Amount
                  <strong>
                    <sup class="fhb-red">*</sup>
                  </strong>
                </label>
              </div>
              <div class="input-section">
                <label for="deposit-amount-one">$0.</label>
                <input
                  type="text"
                  maxlength="2"
                  aria-describedby="deposit-amount-one-input"
                  name="deposit-amount-one"
                  aria-required="true"
                  :disabled="this.processing"
                  id="deposit-amount-one"
                  v-model="depositOne"
                  :class="
                    missingDepositOne || invalidDepositOne
                      ? 'll-textfield-error'
                      : 'll-textfield flex-input'
                  "
                  v-on:blur="validateDepositOne()"
                  data-test="deposit-amount-one"
                />
                <div class="error" role="alert" id="deposit-amount-one-input">
                  <template v-if="missingDepositOne || invalidDepositOne">
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="error"
                    />
                    <div>Please enter a valid amount</div>
                  </template>
                </div>
              </div>

              <!-- row 2 -->
              <div class="label-section">
                <label for="deposit-amount-two">
                  Deposit Amount
                  <strong>
                    <sup class="fhb-red">*</sup>
                  </strong>
                </label>
              </div>
              <div class="input-section">
                <label for="deposit-amount-one">$0.</label>
                <input
                  type="text"
                  maxlength="2"
                  aria-describedby="deposit-amount-two"
                  name="deposit-amount-two"
                  aria-required="true"
                  :disabled="this.processing"
                  id="deposit-amount-two"
                  v-model="depositTwo"
                  :class="
                    missingDepositTwo || invalidDepositTwo ? 'll-textfield-error' : 'll-textfield '
                  "
                  v-on:blur="validateDepositTwo()"
                  data-test="deposit-amount-two"
                />
                <div class="error" role="alert" id="deposit-amount-two">
                  <template v-if="missingDepositTwo || invalidDepositTwo">
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="error"
                    />
                    <div>Please enter a valid amount</div>
                  </template>
                </div>
              </div>
            </div>

            <div class="v-spacer-50">
              <div class="error-container" role="alert" v-if="apiErr">
                <div class="image-container">
                  <img
                    srcset="
                      @/assets/images/error-msg-icon.png    1x,
                      @/assets/images/error-msg-icon-2x.png 2x
                    "
                    src="@/assets/images/error-msg-icon.png"
                    title="error message"
                  />
                </div>
                <div class="error-message">
                  {{ apiErrMsg }}
                </div>
              </div>
            </div>
            <div class="button-container">
              <button
                type="submit"
                :class="processing ? 'processing-button' : 'login-btn'"
                title="next button"
              >
                {{ processing ? "PROCESSING..." : "VERIFY" }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import SplashBackground from "@/components/Splash.vue"
import jquery from "jquery"
import api from "@/app/funding_api"

export default {
  name: "VerifyDeposits",
  components: {
    FormContainer,
    SplashBackground,
  },
  data() {
    return {
      invalidDepositOne: false,
      invalidDepositTwo: false,
      missingDepositOne: false,
      missingDepositTwo: false,
      processing: false,
      next: false,
      depositOne: "",
      depositTwo: "",
      retry: false,
      apiErr: false,
      apiErrMsg:
        "We were unable to process your transfer. Please confirm the information you entered is correct, then try again.",
      cents_regex: /^[0-9]{2}$/,
    }
  },
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("fundnow", [
      "setBankSelected",
      "setAccountType",
      "setAccountNum",
      "setFirstName",
      "setProductName",
      "setPriorityChecking",
      "setCDProduct",
      "setFromFHB",
      "setFromOther",
      "setFromCheck",
      "setCustomerData",
      "setJwtToken",
    ]),

    submitPage() {
      this.validatePage()
      if (this.next) {
        const payload = {
          amount_1: parseFloat(`0.${this.depositOne}`),
          amount_2: parseFloat(`0.${this.depositTwo}`),
        }
        this.verifyDeposits(this.app_id, this.jwtToken, payload)
      }
    },

    async verifyDeposits(funding_id, token, payload) {
      this.apiErr = false
      this.processing = true
      const resp = await api.postVerifyTrialDeposit(funding_id, token, payload)
      this.processing = false
      if (resp.status === 200) {
        if (resp.data.response.verified == true) {
          this.$router.push("/external/verify-external-account")
        } else if (parseInt(resp.data.response.remaining_attempts, 10) > 0) {
          this.retry = true
          return
        } else if (parseInt(resp.data.response.remaining_attempts, 10) == 0) {
          this.$router.push("/external/verification-unsuccessful")
        }
      } else if (resp.status != 500) {
        this.$router.push("/external/something-went-wrong")
      } else {
        this.apiErr = true
      }
      this.retry = true
    },
    /* Validate account fields and request fields */
    validatePage() {
      this.validateDepositOne()
      this.validateDepositTwo()
      if (
        this.missingDepositOne ||
        this.missingDepositTwo ||
        this.invalidDepositOne ||
        this.invalidDepositTwo
      ) {
        this.next = false
      } else {
        this.next = true
      }
    },

    validateDepositOne() {
      // var cd = document.getElementsByName(name)[0]
      this.missingDepositOne = this.depositOne ? false : true
      if (this.missingDepositOne) {
        // cd.setAttribute("aria-invalid", "true")
      } else {
        this.invalidDepositOne = !this.cents_regex.test(this.depositOne)
        // cd.setAttribute("aria-invalid", "false")
      }
    },
    validateDepositTwo() {
      // var cd = document.getElementsByName(name)[0]
      this.missingDepositTwo = this.depositTwo ? false : true
      if (this.missingDepositTwo) {
        // cd.setAttribute("aria-invalid", "true")
      } else {
        //  Will need to check if invalid (falls between min and max)
        this.invalidDepositTwo = !this.cents_regex.test(this.depositTwo)
        // cd.setAttribute("aria-invalid", "false")
      }
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getAccountType",
      "getAccountNum",
      "getBankSelected",
      "getFirstName",
      "getAppID",
      "getJwtToken",
      "getFundingID",
      "getProductName",
      "getPriorityChecking",
      "getCDProduct",
      "getFromFHB",
      "getFromOther",
      "getFromCheck",
      "getCustomerData",
    ]),
    product_name: {
      get() {
        return this.getProductName
      },
      set(v) {
        this.setProductName(v)
      },
    },
    priority_checking: {
      get() {
        return this.getPriorityChecking
      },
      set(v) {
        this.setPriorityChecking(v)
      },
    },
    cd_product: {
      get() {
        return this.getCDProduct
      },
      set(v) {
        this.setCDProduct(v)
      },
    },
    FHBank: {
      get() {
        return this.getFromFHB
      },
      set(v) {
        this.setFromFHB(v)
      },
    },
    otherBank: {
      get() {
        return this.getBankSelected
      },
      set(v) {
        this.setBankSelected(v)
      },
    },
    check: {
      get() {
        return this.getFromCheck
      },
      set(v) {
        this.setFromCheck(v)
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken
      },
      set(v) {
        this.setJwtToken(v)
      },
    },
    app_id: {
      get() {
        return this.getFundingID
      },
      set(v) {
        this.setFundingID(v)
      },
    },
    first_name: {
      get() {
        return this.getFirstName
      },
      set(v) {
        this.setFirstName(v)
      },
    },
    external_account_number: {
      get() {
        return this.getAccountNum.slice(-4)
      },
      set(v) {
        this.setAccountNum(v)
      },
    },
    external_account_type: {
      get() {
        return this.getAccountType
      },
      set(v) {
        this.setAccountType(v)
      },
    },
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.label-section {
  max-width: 346px;
  height: 65px;
  padding: 11px, 0px, 0px, 0px;
  gap: 4px;
  display: inline-block;
  margin: 20px 0;
}
.input-section {
  label {
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
  }
  input {
    max-width: 3em;
  }
  display: flex;
  align-items: center;
  max-width: 300px;
  height: 75px;
  gap: 4px;
}
.form-content-container {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.infobox {
  max-width: 599.1px;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  border: 2px solid $grayLight;
  margin-left: 60px;
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}

.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $yellowHover;
  }
}
.error-container {
  height: 50px;
  border: solid 1px $red;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  text-align: left;
}
.image-container {
  width: 40px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $redLight;
  img {
    width: 18px;
    height: 18px;
  }
}
.error-message {
  padding-left: 15px;
  color: $red;
}
</style>
