<!-- Path: /check/fund-by-check -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Fund Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">By Check</div>
          <div class="v-spacer-20" />

          <div class="info-box">
            <p>
              Separate checks (no money orders) are required for each account being opened and
              should be made payable to yourself.
              {{
                customer_data?.opened_accounts?.length == 1
                  ? "Your check can be deposited by:"
                  : "Checks can be deposited by:"
              }}
            </p>
          </div>

          <template v-if="!cdFound && total <= 20000">
            <div class="check-info">
              <div class="form-header-sub" style="text-align: left !important">
                Mobile Check Deposit
              </div>
              <div class="info-container">
                <img
                  srcset="@/assets/images/mobile-icon.png 1x, @/assets/images/mobile-icon-2x.png 2x"
                  src="@/assets/images/mobile-icon.png"
                  title="Mobile Check"
                />
                <ol>
                  <li>
                    Download and Login to the FHB Mobile app
                    <br />
                    <div class="mobileStoreLogo">
                      <a href="https://apps.apple.com/app/id1172432893" target="_blank"
                        ><img
                          class="appleLogo"
                          src="@/assets/images/Apple_Store_Badge@1.5x.png"
                          alt="apple store badge"
                      /></a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile"
                        target="_blank"
                        ><img
                          class="playstoreLogo"
                          src="@/assets/images/google-play-badge@1.5x.png"
                          alt="play store badge"
                      /></a>
                    </div>
                  </li>
                  <li>Login to the FHB Mobile app and go to Deposits</li>
                  <li>Select your new account to deposit the check into</li>
                </ol>
              </div>
            </div>
          </template>

          <div class="check-info">
            <div class="form-header-sub" style="text-align: left !important">Mail a Check</div>
            <div class="info-container">
              <div class="italic">
                (processed within 1-2 business days<sup>*</sup> after check is received)
              </div>
              <template v-if="customer_data?.opened_accounts?.length == 1">
                <table>
                  <tr>
                    <td>
                      <img
                        srcset="
                          @/assets/images/mail-icon.png    1x,
                          @/assets/images/mail-icon-2x.png 2x
                        "
                        src="@/assets/images/mail-icon.png"
                        title="Mail Check"
                      />
                    </td>
                    <td>FHB Direct</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>PO Box 1959</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Honolulu, HI 96805</td>
                  </tr>
                </table>
              </template>
              <template v-else-if="customer_data?.opened_accounts?.length > 1">
                <img
                  srcset="@/assets/images/mail-icon.png 1x, @/assets/images/mail-icon-2x.png 2x"
                  src="@/assets/images/mail-icon.png"
                  title="Mail Check"
                />
                <ol>
                  <li>
                    Include the last 4-digits of your new account number on each check (no money
                    orders)
                  </li>
                  <li>
                    Mail your check to:
                    <div class="address">
                      <div>FHB Direct</div>
                      <div>PO Box 1959</div>
                      <div>Honolulu, HI 96805</div>
                    </div>
                  </li>
                </ol>
              </template>
            </div>
          </div>
          <div class="note-container">
            <sup>*</sup>A hold may be placed on your check deposit in accordance with our check hold
            policy. For more information refer to the Check Hold Policy for Checking Accounts
            located in the Deposit Account Rules and Regulations and Other Information for Personal
            Accounts.
          </div>
          <template v-if="initAPIError">
            <div class="v-spacer-30" />
            <div role="alert" class="sys-err-msg">
              <div>
                <img
                  class="form-error-icon sys-err-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  aria-hidden="true"
                />{{ errorMessage }}
              </div>
            </div>
            <div class="v-spacer-30" />
          </template>
          <div class="button-container">
            <button type="button" class="back-button" @click="goBack">
              <img
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon.png"
                title="go back"
              />
            </button>
            <button
              :class="processing ? 'processing-button' : 'next-button'"
              type="button"
              @click="handleContinue"
            >
              {{ nextButton }}
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"
import funding_api from "@/app/funding_api"
import helpers from "@/app/helpers"

export default {
  name: "DepositsNotSent",
  components: {
    FormContainer,
    Splash,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      nextButton: "CONTINUE",
      initAPIError: false,
      errorMessage: "",
    }
  },
  created() {
    //  check to see if any of the accounts are CD
    if (this.customer_data && this.customer_data.opened_accounts) {
      for (let acc of this.customer_data.opened_accounts) {
        if (acc.is_cd) {
          this.cdFound = true
        }
      }
    }
  },
  methods: {
    ...mapActions("fundnow", [
      "setCustomerType",
      "setCustomerData",
      "setCDFound",
      "setEnrollmentSSO",
    ]),
    moneyToString(val) {
      if (!val || val == 0) {
        return "$0.00"
      }
      return helpers.formatMoneyToString(val)
    },
    handleContinue() {
      this.postFundingInit()
    },
    goBack() {
      this.$router.push("/initial-deposit")
    },
    async postFundingInit() {
      this.initAPIError = false
      let fundings = {}
      // create map for accounts
      for (let account of this.customer_data.opened_accounts) {
        fundings[account.id] = helpers.formatMoneyFromString(account.deposit_amount)
      }
      const payload = {
        account_id_funding: fundings,
        transfer_type: this.getTransferFrom,
        linked_external_account: false,
        linked_external_internal_id: "",
        account_type: "",
        routing_number: "",
        account_number: "",
        save_account_for_future_transfers: false,
        agreement_accepted: false,
      }
      this.processing = true
      this.nextButton = "PROCESSING..."
      const resp = await funding_api.postFundingInit(this.getFundingID, this.getJwtToken, payload)
      if (resp) {
        if (resp.status === 200) {
          // update maturity date
          for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
            for (let j = 0; j < resp.data.response.accounts.length; j++) {
              if (
                this.customer_data.opened_accounts[i].account_number ==
                resp.data.response.accounts[j].account_number
              ) {
                this.customer_data.opened_accounts[i].maturity_date =
                  resp.data.response.accounts[j].maturity_date
                this.customer_data.opened_accounts[i].funded_at =
                  resp.data.response.accounts[j].funded_at
              }
            }
          }
          if (resp.data?.response?.enrollment_sso) {
            this.setEnrollmentSSO(resp.data.response.enrollment_sso)
          }
          this.$router.push("/check/account-created")
        } else if (resp.status === 422) {
          this.initAPIError = true
          this.errorMessage = "Something went wrong. Please try again later."
        } else {
          this.initAPIError = true
          this.errorMessage = "Something went wrong. Please try again later."
        }
      }
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getTotal",
      "getCustomerData",
      "getCDFound",
      "getFundingID",
      "getJwtToken",
      "getTransferFrom",
    ]),
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal)
      },
    },
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
    cdFound: {
      get() {
        return this.getCDFound
      },
      set(v) {
        this.setCDFound(v)
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn {
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.check-info {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left !important;
  margin: 20px 0;
}
.info-container {
  table {
    margin-top: 10px;
  }
  img {
    margin-top: 10px;
    vertical-align: top;
  }
  ol {
    max-width: 620px;
    display: inline-block;
    margin: 0 0;
  }
}
.mobileStoreLogo {
  margin: 10px 0;
}
.appleLogo {
  padding-right: 10px;
  height: 40px;
  width: 130px;
}
.playstoreLogo {
  height: 40px;
  width: 130px;
}

.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}

.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.address {
  margin-left: 10px;
}
li {
  margin: 10px 0;
}
.note-container {
  background-color: $orangeFaint;
  padding: 15px;
}
.info-box {
  font-size: 18px;
  font-weight: 450;
  span {
    font-weight: 700;
  }
}
</style>
