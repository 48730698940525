<!-- Path: /internal/transfer-from-account -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Fund Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">Transfer from my other FHB account</div>
          <form @submit.prevent="submitInternal">
            <div class="v-spacer-30"></div>
            <div class="section-header">Select the account you want to transfer from</div>
            <div>Total Deposit amount {{ formattedTotal }}</div>
            <!-- display internal accounts  -->
            <div v-for="(account, i) in internal_accounts" :key="i" class="form-content-container">
              <div class="radio-line">
                <input
                  :name="`${account.description}-${i}`"
                  :id="`${account.account_number}-${i}`"
                  type="radio"
                  :value="`${account.account_number}`"
                  v-model="chosenAccount"
                  aria-describedby="missing-selected-account"
                />
                <label :for="`radio-account-${i}`"
                  >{{ account.description }} #{{ account.account_number }}
                </label>
              </div>
            </div>
            <div v-if="internal_accounts.length == 0">No Accounts Selectable</div>
            <div class="v-spacer-30">
              <div
                v-if="missingSelectedAccount"
                class="err-container"
                role="alert"
                id="missing-selected-account"
              >
                <img
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  title="select an account icon"
                />
                Please select an account
              </div>
              <div v-if="transferErr" class="err-container" role="alert">
                <img
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  title="select an account icon"
                />
                {{ transferErrMsg }}
              </div>
            </div>
            <div class="button-container">
              <button type="button" class="back-button" @click="goBack">
                <img
                  srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                  src="@/assets/images/backIcon.png"
                  title="go back icon"
                />
              </button>
              <button :class="processing ? 'processing-button' : 'next-button'">
                {{ processing ? "PROCESSING..." : "NEXT" }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"
import helpers from "@/app/helpers"
import funding_api from "@/app/funding_api"
import jquery from "jquery"

export default {
  name: "InternalTransfer",
  components: {
    FormContainer,
    Splash,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
      missingRequest: false,
      account: "",
      chosenAccount: "",
      transferErr: false,
      transferErrMsg: "Something went wrong with your transfer. Please try again.",
    }
  },
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
  },
  created() {
    // set chosenAccount to allow user to go back to this page and make edits
    this.chosenAccount = this.selected_account.account_number
  },
  methods: {
    ...mapActions("fundnow", [
      "setInternalAccounts",
      "setSelectedAccount",
      "setInternalAccountTransfers",
      "setCustomerData",
      "setEnrollmentSSO",
    ]),

    // submit
    submitInternal() {
      // if account was chosen, match selected account number with that in array.
      if (this.chosenAccount) {
        // find account in array that matches selected account number and return
        this.selected_account = this.internal_accounts.find(
          account => account.account_number.slice(-4) === this.chosenAccount.slice(-4)
        )
      }

      this.validatePage()
      if (!this.missingSelectedAccount) this.postInternalFunding()
    },
    /* Validate account fields and request fields */
    validatePage() {
      this.validateAccounts()
    },
    /* check if validate fields are empty */
    validateAccounts() {
      if (this.selected_account === "") {
        this.next = false
        this.missingSelectedAccount = true
      } else {
        this.next = true
        this.missingSelectedAccount = false
      }
    },
    goBack() {
      this.$router.push("/initial-deposit")
    },

    async postInternalFunding() {
      this.transferErr = false

      const payload = {
        from_account_id: this.selected_account.internal_account_id,
      }
      this.processing = true
      const resp = await funding_api.postInternalFunding(this.funding_id, this.jwtToken, payload)
      this.processing = false

      if (resp) {
        if (resp.status === 200) {
          if (resp.data?.response?.enrollment_sso) {
            this.setEnrollmentSSO(resp.data.response.enrollment_sso)
          }
          // updated funded_at
          for (let i = 0; i < this.customer_data.opened_accounts.length; i++) {
            for (let j = 0; j < resp.data.response.accounts.length; j++) {
              if (
                this.customer_data.opened_accounts[i].account_number ==
                resp.data.response.accounts[j].account_number
              ) {
                this.customer_data.opened_accounts[i].funded_at =
                  resp.data.response.accounts[j].funded_at
              }
            }
          }
          this.internal_account_transfers = resp.data.response
          // if no transfer err and no form errors
          if (this.next) {
            this.$router.push("/internal/transfer-initiated")
          }
        } else if (resp.status == 502) {
          // if timeout
          this.$router.push("/internal/transfer-unsuccessful")
        } else if (resp.status == 202) {
          // partial funding
          this.$router.push("/internal/transfer-failure")
        } else if (resp.status == 422) {
          this.$router.push("/internal/transfer-unsuccessful")
        } else if (resp.status == 409) {
          // select different account
          this.transferErr = true
          this.transferErrMsg =
            "Something went wrong with your transfer. Please select a different account and try again."
        } else if (resp.status == 500) {
          this.transferErr = true
          this.transferErrMsg = "Something went wrong with your transfer. Please try again."
        }
      }
    },
    // format higher numbers with commas
    seperateByThousandths(str, delim) {
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, `${delim}`)
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getInternalAccounts",
      "getSelectedAccount",
      "getCustomerData",
      "getTotal",
      "getJwtToken",
      "getFundingID",
      "getInternalAccountTransfers",
    ]),
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
    internal_accounts: {
      get() {
        let selectable_accounts = []
        for (let i = 0; i < this.getCustomerData.internal_accounts.length; i++) {
          // accounts should only be selectable if the are not CDs, not CLOSER, and have a balance larger than the total deposit amount
          if (
            this.getCustomerData.internal_accounts[i].host_account_type !== "CD" &&
            this.getCustomerData.internal_accounts[i].host_account_type !== "TCD REG" &&
            this.getCustomerData.internal_accounts[i].host_account_type !== "TCD IRA" &&
            !this.getCustomerData.internal_accounts[i].description.toUpperCase().includes("CLOSED")
          ) {
            if (this.getCustomerData.internal_accounts[i].available_balance > this.total) {
              selectable_accounts.push(this.getCustomerData.internal_accounts[i])
            }
          }
        }
        return selectable_accounts
      },
    },
    selected_account: {
      get() {
        return this.getSelectedAccount
      },
      set(v) {
        this.setSelectedAccount(v)
      },
    },
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal)
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      },
    },
    funding_id: {
      get() {
        return this.getFundingID
      },
    },
    jwtToken: {
      get() {
        return this.getJwtToken
      },
    },
    internal_account_transfers: {
      // May need to keep track of failures and successes
      get() {
        return this.getInternalAccountTransfers
      },
      set(v) {
        this.setInternalAccountTransfers(v)
      },
    },
    // accounts that either don't meet the available balance or they are a CD
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.form-content-container {
  margin-left: 20px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.section-2 {
  font-size: 16px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
    margin: 0 2px;
  }
}

.radio-line {
  display: flex;
  margin: 30px 15px;
  input,
  label,
  div {
    justify-self: center;
    vertical-align: middle;
    word-wrap: break-word;
  }
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.back-button {
  background-color: $white;
  width: 60px;
  height: 40px;
  margin-right: 40px;
  border: 1px soli black;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  text-align: center;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $grayVlight;
  }
  img {
    vertical-align: middle;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.current-balance {
  margin-left: 5px;
  color: #676767;
}
.other-accounts {
  color: #676767;
  div {
    display: inline-block;
    column-gap: 5px;
  }
}
</style>
