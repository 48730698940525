export default {
  formatMoneyToString(mon) {
    if (!mon || mon == 0) return "$0.00"
    mon = String(mon)
    if (mon.includes(".")) {
      let parts = mon.split(".")
      if (parts.length == 2) {
        var dol = parts[0]
        var cents = parts[1]
        switch (cents.length) {
          case 0:
            cents = "00"
            break
          case 1:
            cents = `${cents}0`
            break
          case 2:
            break
          default:
            cents = String(cents).substring(0, 2)
        }
        let dolStr = this.seperateByThousandths(dol, ",")
        return `$${dolStr}.${cents}`
      }
    }
    return `$${this.seperateByThousandths(mon, ",")}.00`
  },
  seperateByThousandths(str, delim) {
    // "1000" -> "1,000"
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, `${delim}`)
  },
  formatMoneyFromString(mon) {
    if (mon == "") {
      return 0
    }
    let str = String(mon).replace("$", "").replaceAll(" ", "").replaceAll(",", "")
    let fl = parseFloat(str)
    let ret = +fl
    return ret
  },
  removeLeadingZeros(apy) {
    return Number(apy).toString()
  },
  formatMinMax(money) {
    let minMax = this.formatMoneyToString(money)
    minMax = minMax.replace(".00", "")
    return minMax
  },
  validateDateFormat(date) {
    const regex = /^(1[0-2]|0[1-9])\/(3[01]|[12][0-9]|0[1-9])\/[1-2]{1}[0-9]{3}$/
    return regex.test(date)
  },
  formatInterestRate(interest_rate) {
    if (!interest_rate) return "N/A"
    if (typeof interest_rate === "string") {
      if (interest_rate != "") {
        return `${parseFloat(interest_rate).toFixed(3)}%`
      } else {
        return "N/A"
      }
    } else {
      return `${interest_rate.toFixed(3)}%`
    }
  },
}
