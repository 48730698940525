<!-- Path: /return-expired -->
<template>
  <div class="page">
    <FullSplash />
    <div class="center">
      <FormContainer header="Return to Application Expired">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
              <img
                srcset="@/assets/images/stop-watch.png 1x, @/assets/images/stop-watch-2x.png 2x"
                src="@/assets/images/stop-watch-2x.png"
                title="Deposits failed"
              />
            </div>
            <!-- Application Expired -->
          </div>
          <div class="v-spacer-20" />
          <div class="failed-msg">
            We apologize, this page is no longer available for security purposes. Please login to
            FHB Online Banking or the FHB Mobile app to view your account information or refer to
            your paper statement.
          </div>
          <div class="v-spacer-10" />
          <div class="button-container">
            <button class="login-btn" @click="goToLogin">Login to FHB Online</button>
            <a :href="enrollmentURL" target="_blank">Enroll Now</a>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import FormContainer from "@/components/FormContainer.vue"
import FullSplash from "@/components/FullSplash.vue"
import env from "@/app/env"
import jquery from "jquery"

export default {
  name: "ReturnApplicationExpired",
  components: {
    FormContainer,
    FullSplash,
  },
  data() {
    return {}
  },
  mounted() {
    jquery("html, body").animate({ scrollTop: 0 })
  },
  computed: {
    enrollmentURL: {
      get() {
        return env.enrollmentURL()
      },
    },
  },
  methods: {
    goToLogin() {
      window.open(env.enrolledLoginURL(), "_blank")
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 30px 0;
  button {
    margin-bottom: 1em;
  }
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.login-btn,
.enroll-btn {
  font-size: 1em;
  width: 274px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: none;
  background-color: $yellowLight;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $yellowHover;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 20px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.form-header {
  font-weight: 700;
}
.page {
  background-color: $orangeFocus;
}
.enroll-btn {
  color: $white;
  background-color: $red;
  &:hover,
  :active {
    background-color: $redHover;
  }
}
</style>
