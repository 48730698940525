import NewAccount from "@/views/External/NewAccount"
import ExternalTransfer from "@/views/External/ExternalTransfer"
import ExternalTransferRealtime from "@/views/External/ExternalTransferRealtime"
import RealtimeInit from "@/views/External/RealtimeInit"
import TrialDepositsInit from "@/views/External/TrialDepositsInit.vue"
import DepositsVerificationSuccessful from "@/views/External/DepositsVerificationSuccessful"
import VerifyDeposits from "@/views/External/VerifyDeposits"
import DepositsNotSent from "@/views/ErrorPages/DepositsNotSent"
import AccountVerificationFailed from "@/views/ErrorPages/AccountVerificationFailed"
import VerificationUnsuccessful from "@/views/ErrorPages/VerificationUnsuccessful"
import ExternalTransferRetry from "@/views/ErrorPages/ExternalTransferRetry"
import CannotLinkExternal from "@/views/ErrorPages/ExternalLinkUnsuccessful"
import RealtimeSuccessful from "@/views/External/RealtimeSuccessful"
import RealtimeAuthenticationLogin from "@/views/External/RealtimeAuthenticationLogin"
import RealtimeAuthentication from "@/views/External/RealtimeAuthentication"
import RealtimeUnsuccesful from "@/views/ErrorPages/RealtimeUnsuccessful"
import ExternalFundingDeposits from "@/views/External/ExternalFundingDeposits"
import ExternalExistingSuccess from "@/views/External/ExternalExistingSuccess.vue"
import ExternalFundingFailure from "@/views/ErrorPages/ExternalFundingFailure.vue"
import ExternalFundingConfirm from "@/views/External/ExternalFundingConfirm.vue"
import auth from "../auth_routes"
import store from "@/store"
// import ExternalTransferUnsuccessful from '@/views/ErrorPages/ExternalTransferUnsuccessful.vue'
import ExternalTransferTimeout from "@/views/ErrorPages/ExternalTransferTimeout.vue"
import ExternalSomethingWentWrong from "@/views/ErrorPages/ExternalSomethingWentWrong.vue"
import MaxAttemptsReached from "@/views/ErrorPages/MaxAttemptsReached.vue"
import MaxVerifyAttemptsReached from "@/views/ErrorPages/MaxVerifyAttemptsReached.vue"
import DepositsSentSuccessful from "@/views/External/DepositsSentSuccessful.vue"

const jwtISS = "FUNDING-ISS"

const externalRoutes = {
  getToken() {
    return store.getters["fundnow/getJwtToken"]
  },
  getRedirect() {
    return "/"
  },

  loadRoutes(routes) {
    routes.push(
      {
        path: "/external/transfer-account",
        name: "External Transfer account",
        component: ExternalTransfer,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/confirm-account",
        name: "External Confirm account",
        component: ExternalFundingConfirm,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/new",
        name: "Transfer New Account",
        component: NewAccount,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      // For realtime flow
      {
        path: "/external/realtime-verify",
        name: "Realtime Init",
        component: RealtimeInit,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/realtime-confirm-account",
        name: "External Realtime Confirm Account",
        component: ExternalTransferRealtime,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/realtime-authentication-login",
        name: "External Realtime Authentication Login",
        component: RealtimeAuthenticationLogin,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/realtime-authentication",
        name: "External Realtime Authentication",
        component: RealtimeAuthentication,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/realtime-successful",
        name: "Realtime Successful",
        component: RealtimeSuccessful,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      // For small deposits flow
      {
        path: "/external/trial-deposits-verify",
        name: "Trial Deposits Init",
        component: TrialDepositsInit,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/verification-successful",
        name: "Verification Successful",
        component: DepositsVerificationSuccessful,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/deposits-sent",
        name: "Deposits Sent",
        component: DepositsSentSuccessful,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/deposits-failed",
        name: "Deposits Not Sent",
        component: DepositsNotSent,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/verification-unsuccessful",
        name: "Verification Unsuccessful",
        component: VerificationUnsuccessful,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/account-verification-failed",
        name: "Account Failed",
        component: AccountVerificationFailed,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/realtime-unsuccessful",
        name: "Realtime Unsuccessful",
        component: RealtimeUnsuccesful,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/verify-external-account",
        name: "Verify External Account",
        component: ExternalFundingDeposits,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/existing-external-success",
        name: "ExistingExternal Success",
        component: ExternalExistingSuccess,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/verify-deposits",
        name: "Verify Deposits",
        component: VerifyDeposits,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/transfer-unsuccessful",
        name: "Funding Failure (Retry)",
        component: ExternalTransferRetry,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/funding-failed",
        name: "External Funding Failure",
        component: ExternalFundingFailure,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/cannot-link-external",
        name: "Cannot Link External",
        component: CannotLinkExternal,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/transfer-timeout",
        name: "External Transfer Timeout",
        component: ExternalTransferTimeout,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/external/something-went-wrong",
        name: "External Something Went Wrong",
        component: ExternalSomethingWentWrong,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/max-mfa-attempts",
        name: "Max Attempts Reached (mfa)",
        component: MaxAttemptsReached,
      },
      {
        path: "/max-verify-attempts",
        name: "Max Attempts Reached (verify)",
        component: MaxVerifyAttemptsReached,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      }
    )
    return routes
  },
}

export default externalRoutes
