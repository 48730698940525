import AccountLoadError from "@/views/ErrorPages/AccountLoadError.vue"
import AppNotFound from "@/views/ErrorPages/AppNotFound.vue"
import AccountsClosed from "@/views/ErrorPages/AccountsClosed.vue"
import ReturnApplicationExpired from "@/views/ErrorPages/ReturnApplicationExpired.vue"
import store from "@/store"

const errorRoutes = {
  getToken() {
    return store.getters["fundnow/getJwtToken"]
  },
  getRedirect() {
    return "/"
  },
  loadRoutes(routes) {
    routes.push(
      {
        path: "/error-loading-accounts",
        name: "Error loading accounts",
        component: AccountLoadError,
      },
      {
        path: "/application-not-found",
        name: "Application not found",
        component: AppNotFound,
      },
      {
        path: "/accounts-closed",
        name: "Accounts Closed",
        component: AccountsClosed,
      },
      {
        path: "/return-expired",
        name: "Application is expired",
        component: ReturnApplicationExpired,
      }
    )
    return routes
  },
}

export default errorRoutes
