<template>
  <div class="cert-of-deposit">
    <div style="text-decoration: underline">
      <img
        v-if="!toggleDetails"
        srcset="@/assets/images/caretDown.png 1x, @/assets/images/caretDown-2x.png 2x"
        src="@/assets/images/caretDown.png"
        @click="toggle"
        class="caret"
      />
      <img
        v-if="toggleDetails"
        srcset="@/assets/images/caretUp.png 1x, @/assets/images/caretUp-2x.png 2x"
        src="@/assets/images/caretUp.png"
        @click="toggle"
        class="caret"
      />
      Account Details
    </div>
    <!-- transition dropdown -->
    <Transition name="slide-fade">
      <div v-if="toggleDetails">
        <div class="cd">
          <div>
            <div>Account Number: {{ account.account_number }}</div>
            <div>Interest Rate: {{ formattedInterestRate(account.interest_rate) }}</div>
            <div>
              Term: {{ account.cd_term }} {{ account.term_indicator == "M" ? "months" : "years" }}
            </div>
            <div>Intended Deposit Amount: {{ ifWholeNumber(account.deposit_amount) }}</div>
          </div>
          <div>
            <div>
              Date of Deposit: TBD
              <div class="note">(accounts not funded within 30 days will be closed)</div>
            </div>
            <div>Maturity Date: {{ reFormatDate(account.maturity_date) }}</div>
            <div>Annual Percentage Yield {{ parseFloat(account.apy).toFixed(2) }}%</div>
            <div>Interest Disbursement<sup>*</sup>: Add to Principal</div>
          </div>
        </div>
        <!-- certificate -->
        <div>
          The stated interest rate and Annual Percentage Yield (APY) are based on the date you
          submitted your application. Interest will begin to accrue as explained in the section
          titled
          <i
            >Online Certificate of Deposit in the Important Terms and Conditions and Schedule of
            Charges for Personal Deposit Accounts</i
          >. Actual earnings may differ depending on the actual Date of Deposit.
        </div>
        <div>
          You can find more information about your Online Certificate of Deposit accounts in the
          account materials titled “General Account Disclosures” provided to you at account opening.
          If you have any other questions regarding your Online Certificate of Deposit account
          please call us at (808) 844-4545 or toll-free at (800) 894-5600.
        </div>
        <div>
          <sup>*</sup>Interest earned on your Online Certificate of Deposit will automatically be
          added back to your principal at maturity, or annually if your Online Certificate of
          Deposit term is longer than one year.
        </div>
      </div>
    </Transition>
  </div>
  <div v-if="!isLast" class="border-after-cd" />
</template>

<script>
import helpers from "@/app/helpers"
export default {
  name: "CDDetailsContainer",
  emits: ["click"],
  props: {
    account: Object,
    toggleDetails: Boolean,
    isLast: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    toggle() {
      this.$emit("click")
    },
    ifWholeNumber(v) {
      try {
        if (v.includes(".")) {
          return v
        }
        return helpers.formatMoneyToString(helpers.formatMoneyFromString(v))
      } catch {
        return helpers.formatMoneyToString(v)
      }
    },
    // convert maturity date format from YYYY-MM-DD to MM/DD/YYYY
    reFormatDate(d) {
      try {
        const date = d.split("-")
        const newDate = `${date[1]}/${date[2]}/${date[0]}`
        return newDate
      } catch {
        return d
      }
    },
    formattedInterestRate(interestRate) {
      return helpers.formatInterestRate(interestRate)
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.cd {
  padding: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
  border: solid 1px $grayLight;
  height: max-content;
  word-wrap: break-word;
  word-break: keep-all;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.cert-of-deposit {
  display: block;
  border: solid 2px $grayLight;
  padding: 10px 15px;
  margin: 0px 15px;
  @include media-breakpoint-up(sm) {
    padding: 20px 30px;
  }

  div {
    margin: 10px 0;
  }
}
.caret {
  height: 12px;
  width: 18px;
  margin-right: 5px;

  &:hover,
  :active {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.border-after-cd {
  margin-top: 15px;
  border-bottom: solid 1px $gray;
}
.note {
  font-size: 12px;
  padding: 0px !important;
  word-wrap: break-word;
  word-break: keep-all;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  margin: 0px !important;
}
</style>
