<!-- Path:  /return/access-application/:id-->
<template>
  <div class="page">
    <SplashBackground />
    <div class="center">
      <FormContainer header="Access Application">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header app-header">
            Please enter the primary account holder's information to view an application.
          </div>
          <form @submit.prevent="handleContinue">
            <div class="app-sub-header">
              Required fields are indicated by a red asterisk(<sup class="fhb-red">*</sup>)
            </div>
            <div class="v-spacer-40" />
            <div class="form-div">
              <div id="last-name-container" class="form-row">
                <label for="last-name" class="tf-label"
                  >Last Name <sup class="fhb-red">*</sup></label
                >
                <input
                  :class="
                    missingLastName || invalidLastName ? 'll-textfield-error' : 'll-textfield'
                  "
                  name="last_name"
                  id="last-name"
                  v-model="last_name"
                  @blur="validateLastName"
                  aria-describedby="last-name-error"
                  required
                />
                <div class="error-container" role="alert" id="last-name-error">
                  <div class="error" v-if="missingLastName || invalidLastName">
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="error"
                    />
                    <span v-if="missingLastName"> Please enter your last name </span>
                    <span v-else-if="invalidLastName"> Please enter a valid name </span>
                  </div>
                </div>
              </div>
              <div id="dob-container" class="form-row">
                <label class="tf-label" for="date-of-birth"
                  >Date of Birth <sup class="fhb-red">*</sup></label
                >
                <DateInput
                  :class="
                    missingDOB || invalidDOB || invalidDOBFormatting
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  name="date-of-birth"
                  id="date-of-birth"
                  v-model="dob"
                  @blur="validateDOB"
                  :key="date_num"
                  aria-describedby="dob-error"
                  required
                />
                <div class="error-container">
                  <div
                    class="error"
                    v-if="missingDOB || invalidDOB || invalidDOBFormatting"
                    id="dob-error"
                    role="alert"
                  >
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="error"
                    />
                    <span v-if="missingDOB"> Please enter your date of birth </span>
                    <span v-else-if="invalidDOB"> Please enter a valid date </span>
                    <span v-else-if="invalidDOBFormatting">
                      Please enter a valid date (MM/DD/YYYY)
                    </span>
                  </div>
                </div>
              </div>
              <div id="ssn-container" class="form-row-ssn">
                <label class="tf-label" for="ssn"
                  >Social Security Number <sup class="fhb-red">*</sup></label
                >
                <SocialSecurity
                  :class="missingSSN || invalidSSN ? 'll-textfield-error' : 'll-textfield'"
                  name="ssn"
                  id="ssn"
                  v-model="ssn"
                  @blur="validateSSN"
                  aria-describedby="ssn-error"
                  required
                />
                <div class="tf-note">
                  Your Social Security Number (SSN) is used for identification purposes.
                </div>
                <div class="error-container" id="ssn-error" role="alert">
                  <div class="error" v-if="missingSSN || invalidSSN">
                    <img
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg.png"
                      title="missing request"
                    />
                    <span v-if="missingSSN"> Please enter your Social Security Number </span>
                    <span v-else-if="invalidSSN">
                      Please enter a valid Social Security Number
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-60">
              <div v-if="api_err" class="api-error-box" id="network-request-error">
                {{ this.api_err }}
              </div>
            </div>
            <div class="v-spacer-20" />
            <div class="button-container">
              <button
                type="submit"
                :class="processing ? 'processing-button' : 'next-button'"
                title="next button"
              >
                {{ processing ? "PROCESSING..." : "CONTINUE" }}
              </button>
            </div>
          </form>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormContainer from "@/components/FormContainer.vue";
import SocialSecurity from "@/components/Inputs/SocialSecurity.vue";
import SplashBackground from "@/components/Splash.vue";
import jQuery from "jquery";
import DateInput from "@/components/Inputs/DateInput.vue";
import validation from "@/app/validation.js";
import api from "@/app/funding_api.js";
import helpers from "@/app/helpers";

export default {
  name: "ReturnAccess",
  components: {
    FormContainer,
    DateInput,
    SplashBackground,
    SocialSecurity,
  },
  data() {
    return {
      invalidLastName: false,
      invalidDOB: false,
      invalidDOBFormatting: false,
      invalidSSN: false,
      missingLastName: false,
      missingDOB: false,
      missingSSN: false,
      processing: false,
      next: false,
      missingRequest: false,
      payloadSSN: "",
      errCnt: [],
      api_err: "",
      date_num: 0,
    };
  },
  methods: {
    ...mapActions("fundnow", [
      "setMFAInfo",
      "setMFAPhones",
      "setMFAEmails",
      "setDOB",
      "setSSN",
      "setLastName",
      "setFundingID",
      "resetAll",
    ]),

    handleContinue() {
      this.validatePage();
    },

    async postAccess(payload) {
      this.api_err = "";
      this.processing = true;
      const resp = await api.returnToAppMFAInit(this.fundingID, payload);
      this.processing = false;

      if (resp) {
        if (resp.status == 200 && resp.data?.response) {
          if (resp.data.response.methods.includes("EMAIL")) {
            this.mfa_emails = resp.data.response.emails;
          }
          if (resp.data.response.methods.includes("SMS")) {
            this.mfa_phones = resp.data.response.phone_numbers;
          }
          if (this.next) {
            this.$router.push("/return/enhanced-security");
          }
        } else if (resp.status == 403 && resp.data?.response?.accounts_closed) {
          this.$router.push("/accounts-closed");
        } else if (resp.status == 403 && resp.data?.response?.expired === true) {
          this.$router.push("/return-expired");
        } else if (resp.status == 404 || resp.status == 403) {
          this.api_err = "Please confirm the information you entered is correct, then try again.";
        } else if (resp.status == 422) {
          // MFA cooldown not completed
          this.api_err =
            "We are unable to send the verification code at this time. Please try again later.";
        } else {
          this.api_err = "Network error occurred. Please try again later.";
        }
      } else {
        this.api_err = "Network error occurred. Please try again later.";
      }
    },
    /* Validate account fields and request fields */
    validatePage() {
      this.checkErrors();
      this.$nextTick(() => {
        this.errCnt = jQuery(".error").length;
        // check if any invalid or missing fields
        if (!this.errCnt) {
          this.next = true;
          const payload = {
            last_name: this.last_name,
            dob: this.dob,
            ssn: this.ssn,
          };

          this.postAccess(payload);
        } else if (this.errCnt === 1) {
          this.next = false;
        } else {
          this.next = false;
        }
      });
    },
    // validate last name
    validateLastName() {
      var name = document.getElementsByName("last_name")[0];
      this.missingLastName = this.last_name ? false : true;

      if (this.missingLastName) {
        name.setAttribute("aria-invalid", "true");
      } else if (!this.missingLastName) {
        this.invalidLastName = !validation.isValidName(this.last_name);
        if (this.invalidLastName) {
          name.setAttribute("aria-invalid", "true");
        } else {
          this.missingLastName = false;
          this.invalidLastName = false;
          name.setAttribute("aria-invalid", "false");
        }
      }
    },
    validateDOB() {
      this.date_num++;
      var dobElement = document.getElementsByName("date-of-birth")[0];
      this.invalidDOB = false;
      this.invalidDOBFormatting = false;
      this.missingDOB = this.dob ? false : true;
      // helper to make sure month and day are valid
      if (!this.missingDOB) {
        this.invalidDOBFormatting = !helpers.validateDateFormat(this.dob);
        if (!this.invalidDOBFormatting) {
          const today = new Date();
          try {
            const enteredDate = new Date(this.dob);

            // date entered is past today's date
            this.invalidDOB = enteredDate > today;
          } catch {
            this.invalidDOB = true;
          }
        }
      }
      dobElement.setAttribute(
        "aria-invalid",
        this.missingDOB || this.invalidDOBFormatting || this.invalidDOB ? "true" : "false"
      );
    },
    validateSSN() {
      var ssn = document.getElementsByName("ssn")[0];
      this.missingSSN = this.ssn ? false : true;

      if (this.missingSSN) {
        ssn.setAttribute("aria-invalid", "true");
      } else if (!this.missingSSN && this.ssn.length < 9) {
        this.invalidSSN = true;
        if (this.invalidSSN) {
          ssn.setAttribute("aria-invalid", "true");
        }
      } else {
        this.invalidSSN = false;
        this.missingSSN = false;
        ssn.setAttribute("aria-invalid", "false");
      }
    },
    checkErrors() {
      this.validateLastName();
      this.validateDOB();
      this.validateSSN();
    },
    addSSNHyphens(str) {
      let a = str.substring(0, 3);
      let b = str.substring(3, 5);
      let c = str.substring(5, 9);
      return `${a}-${b}-${c}`;
    },
  },
  computed: {
    ...mapGetters("fundnow", [
      "getMFAInfo",
      "getMFAPhones",
      "getMFAEmails",
      "getDOB",
      "getSSN",
      "getFundingID",
      "getLastName",
    ]),

    mfa_info: {
      get() {
        return this.getMFAInfo;
      },
      set(v) {
        this.setMFAInfo(v);
      },
    },
    mfa_phones: {
      get() {
        return this.getMFAPhones;
      },
      set(v) {
        this.setMFAPhones(v);
      },
    },
    mfa_emails: {
      get() {
        return this.getMFAEmails;
      },
      set(v) {
        this.setMFAEmails(v);
      },
    },
    dob: {
      get() {
        return this.getDOB;
      },
      set(v) {
        this.setDOB(v);
      },
    },
    fundingID: {
      get() {
        return this.getFundingID;
      },
      set(v) {
        this.setFundingID(v);
      },
    },
    last_name: {
      get() {
        return this.getLastName;
      },
      set(v) {
        this.setLastName(v);
      },
    },
    ssn: {
      get() {
        return this.getSSN;
      },
      set(v) {
        this.setSSN(v);
      },
    },
  },
  created() {
    this.fundingID = this.$route.params.id;
  },
};
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.label-section {
  max-width: 346px;
  height: 65px;
  padding: 11px, 0px, 0px, 0px;
  gap: 4px;
  display: inline-block;
  margin: 20px 0;
}
.input-section {
  max-width: 300px;
  height: 75px;
  gap: 4px;
}
.form-content-container {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.divider {
  border-top: solid 1px black;
  padding: 20px 0;
}
.total {
  width: 100%;
  margin-left: 14px;
  div {
    display: inline-block;
  }
}
.section-2 {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}

.radio-line {
  display: flex;
  margin: 15px;
}
.next-button {
  background-color: $yellowLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
  cursor: pointer;
  &:hover,
  :active {
    cursor: pointer;
    background-color: $yellowHover;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  position: relative;
  @include media-breakpoint-down(sm) {
    margin: 10px 0;
    margin-top: 30px;
  }
}
.infobox {
  max-width: 599.1px;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-align: left;
  border: 2px solid $grayLight;
  margin-left: 60px;
}
.info-icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
.error {
  color: $red;
  display: flex;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
    margin-top: 3px;
  }
}
.form-div {
  width: 100%;
}
.form-row {
  margin-bottom: 50px;
  input {
    max-width: 100% !important;
    height: 56px;
  }
}
.form-row-ssn {
  margin-bottom: 10px;
  input {
    max-width: 100% !important;
    height: 56px;
  }
}
.app-sub-header {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.011em;
  @include media-breakpoint-down(sm) {
    text-align: left;
    font-size: 15px;
  }
}
.app-header {
  text-align: center;
  margin: 0 50px;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.011em;
  @include media-breakpoint-down(md) {
    margin: 0 30px;
  }
}

.api-error-box {
  // display: flex;
  padding: 10px 10px;
  border: 2px solid $red;
  border-radius: 4px;
  align-items: center;
  padding-left: 1em;
  color: $red;
  margin-top: 40px;
}
.processing-button {
  background-color: $grayLight;
  width: 274px;
  height: 40px;
  border: none;
  border-radius: 999px;
  padding: 4px, 24px, 4px, 24px;
}
.error-container {
  height: 10px;
  margin-top: 5px;
  // @include media-breakpoint-down(sm) {
  //   height: 20px;
  // }
}
.err-img {
  display: inline-block;
}
.form-header {
  @include media-breakpoint-down(sm) {
    font-size: 18px;
    text-align: left;
    margin: 0;
  }
}
</style>
