export default {
  external_transfer_max: 250_000,
  oao_fhb_product_params: {
    ecd: "eCD",
    "6_month_cd_promo": "6 Months eCD Promo Rate",
    "9_month_cd_promo": "9 Months eCD Promo Rate",
    "12_month_cd_promo": "12 Months eCD Promo Rate",
    "18_month_cd_promo": "18 Months eCD Promo Rate",
    "24_month_cd_promo": "24 Months eCD Promo Rate",
    "36_month_cd_promo": "36 Months eCD Promo Rate",
    personal_checking: "Personal Checking",
    pure_checking: "Pure Checking",
    priority_banking_gold: "Priority Banking Gold",
    priority_banking_platinum: "Priority Banking Platinum",
    regular_savings: "Regular Savings",
    personal_savings: "Personal Savings",
    maximizer: "MaxiMizer",
  },
  ProductTypeDictionary: {
    "6_month_eCD": "6 Months eCD",
    "9_month_eCD": "9 Months eCD",
    "12_month_eCD": "12 Months eCD",
    "18_month_eCD": "18 Months eCD",
    "24_month_eCD": "24 Months eCD",
    "36_month_eCD": "36 Months eCD",
    "6_month_cd_promo": "6 Months eCD Promo Rate",
    "9_month_cd_promo": "9 Months eCD Promo Rate",
    "12_month_cd_promo": "12 Months eCD Promo Rate",
    "18_month_cd_promo": "18 Months eCD Promo Rate",
    "24_month_cd_promo": "24 Months eCD Promo Rate",
    "36_month_cd_promo": "36 Months eCD Promo Rate",
    pure_checking: "Pure Checking",
    priority_banking_gold: "Priority Banking Gold",
    priority_banking_platinum: "Priority Banking Platinum",
    "priority_bnkg_plat ": "Priority Banking Platinum", // FIS/IBS might have this string. So we may see this from /v1/accounts
    private_banking_yesSavings: "Private Banking YesSavings",
    pvt_bnk_yessavings: "Private Banking YesSavings", // FIS/IBS might have this string. So we may see this from /v1/accounts
    regular_savings: "Regular Savings",
    maxiMizer: "MaxiMizer",
  },
  q2_product_redirect: {
    ecd: "",
    "6_month_cd_promo": "&productId=6_month_cd_promo",
    "9_month_cd_promo": "&productId=9_month_cd_promo",
    "12_month_cd_promo": "&productId=12_month_cd_promo",
    "18_month_cd_promo": "&productId=18_month_cd_promo",
    "24_month_cd_promo": "&productId=24_month_cd_promo",
    "36_month_cd_promo": "&productId=36_month_cd_promo",
    personal_checking: "",
    pure_checking: "&productId=pure_checking",
    priority_banking_gold: "&productId=priority_banking_gold",
    priority_banking_platinum: "&productId=priority_banking_platinum",
    regular_savings: "&productId=regular_savings",
    personal_savings: "",
    maximizer: "&productId=maximizer",
  },
  q2_savings_product_map: {
    private_banking_yesSavings: true,
    private_banking_yessavings: true,
    pvt_bnk_yessavings: true, // FIS/IBS might have this string. So we may see this from /v1/accounts
    regular_savings: true,
    maxiMizer: true,
    maximizer: true,
  },
  extn_retry_page_success_redirects: {
    REALTIME: "/external/realtime-successful",
    TRIAL: "/external/verification-successful",
    LINKED: "/external/existing-external-success",
  },
}
