<!-- Path: /internal/partial-failure -->
<!-- TODO: set up and integrate with upcoming ticket DIG-2843 -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Something Went Wrong">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
              <img
                srcset="@/assets/images/failed-error.png 1x, @/assets/images/failed-error-2x.png 2x"
                src="@/assets/images/failed-error.png"
                title="Deposits failed"
              />
            </div>
            Transfer Unsuccessful
          </div>

          <div class="v-spacer-20" />
          <div class="failed-msg">
            We were not able to process your transfer from
            <!-- {{ selectedAccount.description }} x{{ account_num}} to: -->
            <!-- <div v-for="(account, index) in created_accounts" :key="index" >
              {{ account.account_type }} {{ account.account_number }} = {{ account.deposit_amount }}
            </div> -->
            <div>
              However, the transfer to your new
              {{}} account could not be completed. Please deposit a check to fund your {{  }}
              account.
            </div>
            <div>
              The check should be written out to yourself in the amount of {{}} and can be deposited
              by*:
            </div>
          </div>

          <div class="check-info" v-if="!cdFound && total <= 20000">
            <div class="form-header-sub" style="text-align: left !important">
              Mobile Check Deposit
            </div>
            <div class="info-container">
              <img
                srcset="@/assets/images/mobile-icon.png 1x, @/assets/images/mobile-icon-2x.png 2x"
                src="@/assets/images/mobile-icon.png"
                title="Mobile Check"
              />
              <ol>
                <li>
                  Download and Login to the FHB Mobile app
                  <br />
                  <div class="mobileStoreLogo">
                    <a href="https://apps.apple.com/app/id1172432893" target="_blank"
                      ><img
                        class="appleLogo"
                        src="@/assets/images/Apple_Store_Badge@1.5x.png"
                        alt="apple store badge"
                    /></a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile"
                      target="_blank"
                      ><img
                        class="playstoreLogo"
                        src="@/assets/images/google-play-badge@1.5x.png"
                        alt="play store badge"
                    /></a>
                  </div>
                </li>
                <li><div>Login to the FHB Mobile app Go to "Deposits"</div></li>
                <li>
                  Select your new account and take a picture of the check you want to deposit into
                  your new account
                  <div>
                    <i v-if="customer_data.opened_accounts.length > 1"
                      >(A separate check is needed per account being funded)</i
                    >
                  </div>
                </li>
              </ol>
            </div>
          </div>

          <div class="check-info">
            <div class="form-header-sub" style="text-align: left !important">Mail a Check</div>
            <div class="info-container">
              <div>(processed within 1-2 business days<sup>*</sup> after check is received)</div>
              <img
                srcset="@/assets/images/mail-icon.png 1x, @/assets/images/mail-icon-2x.png 2x"
                src="@/assets/images/mail-icon.png"
                title="Mail Check"
              />
              <ol>
                <li>Make your check payment to yourself</li>
                <li>
                  The check amount should be your total deposit amount
                  <br />
                  <strong>{{ formattedTotal }}</strong>
                </li>
                <li>
                  Mail your check to:
                  <div class="address">
                    <div>FHB Direct</div>
                    <div>PO Box 1959</div>
                    <div>Honolulu, HI 96805</div>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div class="hold-policy">
            <sup>*</sup>A hold may be placed on your check deposit in accordance with our check hold
            policy. For more information refer to the Check Hold Policy for Checking Accounts
            located in the Deposit Account Rules and Regulations and Other Information for Personal
            Accounts.
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"
import helpers from "@/app/helpers"

export default {
  name: "InternalTransferPartialFailure",
  components: {
    FormContainer,
    Splash,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
    }
  },
  created() {
    if (this.customer_data && this.customer_data.opened_accounts) {
      for (let acc of this.customer_data.opened_accounts) {
        if (acc.is_cd) {
          this.cdFound = true
        }
      }
    }
  },
  methods: {
    ...mapActions("fundnow", ["setCustomerType", "setCustomerData", "setCDFound"]),

    goToExternalMethods() {
      this.$router.push("/initial-deposit")
    },
    goBack() {
      this.$router.push("/initial-deposit")
    },
  },
  computed: {
    ...mapGetters("fundnow", ["getTotal", "getCustomerData", "getCDFound"]),
    total: {
      get() {
        return helpers.formatMoneyFromString(this.getTotal)
      },
    },
    formattedTotal: {
      get() {
        return helpers.formatMoneyToString(this.total)
      },
    },
    customer_data: {
      get() {
        return this.getCustomerData
      },
      set(v) {
        this.setCustomerData(v)
      },
    },
    cdFound: {
      get() {
        return this.getCDFound
      },
      set(v) {
        this.setCDFound(v)
      },
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.close-btn {
  width: 200px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid black;
  background-color: $white;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $grayVlight;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 18px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.011em;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
.check-info {
  font-size: 18px;
  font-weight: 450;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left !important;
  margin: 20px 0;
}
.info-container {
  img {
    margin-top: auto;
    vertical-align: top;
  }
  ol {
    max-width: 620px;
    display: inline-block;
    margin: 0 0;
  }
  li {
    margin: 5px 0;
  }
}
.mobileStoreLogo {
  margin: 10px 0;
}
.appleLogo {
  padding-right: 10px;
  height: 40px;
  width: 130px;
}
.playstoreLogo {
  height: 40px;
  width: 130px;
}
.footer-note {
  background-color: $orangeFaint;
  padding: 20px;
}
.hold-policy {
  background-color: $orangeFaint;
  padding: 15px;
}
</style>
