import FundByCheck from "@/views/Check/FundByCheck"
import CheckAccountCreated from "@/views/Check/CheckAccountsCreated"
import auth from "../auth_routes"
import store from "@/store"

const jwtISS = "FUNDING-ISS"

const checkRoutes = {
  getToken() {
    return store.getters["fundnow/getJwtToken"]
  },
  getRedirect() {
    return "/"
  },
  loadRoutes(routes) {
    routes.push(
      {
        path: "/check/fund-by-check",
        name: "Fund By Check",
        component: FundByCheck,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/check/account-created",
        name: "Account Created",
        component: CheckAccountCreated,
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      }
    )
    return routes
  },
}

export default checkRoutes
