<template>
  <div class="tf-note savings-detail">
    <div>ATM Card (Primary Applicant): {{ account.primary_card ? "Yes" : "No" }}</div>
    <div v-if="account.is_joint">
      ATM Card (Joint Applicant): {{ account.joint_card ? "Yes" : "No" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SavingsDetails",
  props: {
    account: Object,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.cd {
  padding: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 27px;
  letter-spacing: -0.011em;
  text-align: left;
  border: solid 1px $grayLight;
}
.cert-of-deposit {
  display: block;
  width: 100%;
  border: solid 2px $grayLight;
  padding: 20px 30px;
  margin: 0px 15px;

  div {
    margin: 10px 0;
  }
}
.caret {
  height: 12px;
  width: 18px;
  margin-right: 5px;

  &:hover,
  :active {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.border-after-cd {
  margin-top: 15px;
  border: solid 1px $gray;
}
.savings-detail {
  @include media-breakpoint-down(sm) {
    margin-left: 20px;
  }
}
</style>
