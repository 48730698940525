export default {
  validateRoutingNumber(routing_num) {
    const regex = /^\d+$/
    if (routing_num.length < 9 || !regex.test(routing_num)) {
      return false
    } else {
      return true
    }
  },
  validateAccountNumber(account_num) {
    const regex = /^\d+$/
    if (account_num.length < 7 || !regex.test(account_num)) {
      return false
    } else {
      return true
    }
  },
  isValidName(str) {
    const regex = /^[a-zA-Z ^\-'`āēīōūĀĒĪŌŪ]+$/

    if (!str.match(regex)) {
      return false
    }

    return true
  },
}
