<template>
  <div class="grid">
    <div>
      <label id="label">{{ label }}</label>
      <strong v-if="required">
        <sup class="fhb-red">*</sup>
      </strong>
    </div>
    <div>
      <div class="radio-grid">
        <div class="input-container">
          <input
            :name="`${name}-1`"
            :id="`radio-${id}-1`"
            type="radio"
            :value="firstValue"
            @change="changeValue(firstValue, id, 1)"
            :disabled="disable"
            :aria-describedby="ariaDescribe"
          />
          <label id="label-one" :for="`radio-${id}-1`">{{ labelOne }}</label>
        </div>
        <div class="input-container">
          <input
            :name="`${name}-2`"
            :id="`radio-${id}-2`"
            type="radio"
            :value="secondValue"
            @change="changeValue(secondValue, id, 2)"
            :disabled="disable"
            :aria-describedby="ariaDescribe"
          />
          <label id="label-two" :for="`radio-${id}-2`">{{ labelTwo }}</label>
        </div>
      </div>
      <div class="error" role="alert" :id="ariaDescribe">
        <template v-if="missing">
          <img
            srcset="
              @/assets/images/exclaimation-lg.png    1x,
              @/assets/images/exclaimation-lg-2x.png 2x
            "
            src="@/assets/images/exclaimation-lg.png"
          />
          <div v-if="missing">
            {{ error }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleRadioInput",
  props: {
    modelValue: String,
    labelOne: String,
    labelTwo: String,
    name: String,
    id: String,
    valueOne: String,
    valueTwo: String,
    disable: Boolean,
    label: String,
    required: Boolean,
    error: String,
    missing: Boolean,
    ariaDescribe: String,
  },
  mounted() {
    let radio
    if (this.modelValue === this.firstValue) {
      radio = document.getElementById(`radio-${this.id}-1`)
      radio.checked = true
    } else if (this.modelValue === this.secondValue) {
      radio = document.getElementById(`radio-${this.id}-2`)
      radio.checked = true
    }
  },
  data() {
    return {
      // hasDecimal: false,
      // maxCents: false,
      element: null,
      formatted: "",
      raw: "",
      firstValue: this.valueOne,
      secondValue: this.valueTwo,
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        return 91
      },
    },
  },
  methods: {
    changeValue(value, id) {
      let radio
      if (value === this.firstValue) {
        radio = document.getElementById(`radio-${id}-2`)
        radio.checked = false
      } else {
        radio = document.getElementById(`radio-${id}-1`)
        radio.checked = false
      }
      this.$emit("update:modelValue", value)
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.radio-grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
input[type="radio"] {
  width: 25px;
  height: 25px;
  margin: 0 8px;
  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
    margin: 10px 2px;
    margin-left: 0px;
  }
}
.input-container {
  input {
    display: inline-block;
    justify-self: center;
    vertical-align: middle;
  }
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
</style>
