<template>
  <div class="form-container">
    <div>
      <div class="page-header no-print">{{ header }}</div>
      <div v-if="subheader" class="sub-header">{{ subheader }}</div>
      <div class="form">
        <slot name="form-content"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormContainer",
  props: {
    header: String,
    subheader: String,
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.form {
  background-color: $white;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 0px 24px -3px #00000026;
  padding: 60px;
  @include media-breakpoint-down(sm) {
    margin: 0 0;
    padding: 60px 20px;
  }
}
.form-container {
  width: 100%;
}
.page-header {
  font-size: 40px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.011em;
  text-align: center;
  margin: 30px 0;
  word-wrap: break-word;
  word-break: keep-all;
  @include media-breakpoint-down(sm) {
    margin: 10px 0;
    font-size: 30px;
  }
}
.sub-header {
  font-size: 22px;
  line-height: 66px;
  letter-spacing: -0.011em;
  text-align: center;
  margin-top: -60px;
  margin-bottom: 20px;
}
</style>
