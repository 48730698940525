<template>
  <div class="input-container">
    <label :for="id"
      >{{ label }}
      <strong v-if="required">
        <sup class="fhb-red">*</sup>
      </strong>
    </label>
    <div>
      <input
        type="text"
        minlength="9"
        maxlength="9"
        :value="modelValue"
        :id="id"
        :class="missing || invalid ? 'll-textfield-error' : 'll-textfield '"
        :aria-describedby="ariaDescribe"
        :name="name"
        :data-test="test"
        :aria-required="required"
        @blur="handleBlur"
        :disabled="disable"
        :required="required"
      />
      <div class="error" :id="ariaDescribe" role="alert">
        <template v-if="missing || invalid">
          <img
            srcset="
              @/assets/images/exclaimation-lg.png    1x,
              @/assets/images/exclaimation-lg-2x.png 2x
            "
            src="@/assets/images/exclaimation-lg.png"
            title="routing number error"
          />
          <div v-if="missing" id="missing">Please enter a value</div>
          <div v-if="invalid && !missing" id="incorrect">Enter a 9 digit routing number</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountNumber",
  emits: ["blur", "update:modelValue"],
  props: {
    modelValue: String,
    label: String,
    id: String,
    missing: Boolean,
    invalid: Boolean,
    required: Boolean,
    ariaDescribe: String,
    name: String,
    test: String,
    disable: Boolean,
  },
  data() {
    return {
      // hasDecimal: false,
      // maxCents: false,
      element: null,
      formatted: "",
      raw: "",
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        return 91
      },
    },
  },
  methods: {
    getBrowser() {
      let agent = navigator.userAgent
      if (agent.match("/chrome|chromium|crios/i")) return "chrome"
      if (agent.match("/firefox|fxios/i")) return "firefox"
      if (agent.match("/safari/i")) return "safari"
      if (agent.match("/edg/i")) return "edge"
    },
    handleBlur(e) {
      this.$emit("update:modelValue", e.target.value.trim())
      this.$emit("blur")
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.input-container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
  @include media-breakpoint-down(sm) {
    height: 45px;
  }
}
</style>
