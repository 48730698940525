<script>
export default {
  emits: ["retry", "keepOpen", "close"],
  name: "InitialDepositRetry",
  methods: {
    retry() {
      this.$emit("retry")
    },
    keepOpen() {
      this.$emit("keepOpen")
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop=""
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Something went wrong </slot>
        </header>

        <section class="modal-body" id="modalDescription">
          <slot name="body">
            We could not retrieve your accounts at this time.
            <div>Please try again.</div>
          </slot>
        </section>

        <footer class="modal-footer">
          <button type="button" class="close-btn" @click="close" aria-label="Close modal">
            CLOSE
          </button>
          <button type="button" class="btn-green" @click="retry" aria-label="retry call">
            RETRY
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.3); */
  background-color: rgba(253, 247, 242, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  /* box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4); */
  box-shadow: rgb(160 170 180 / 40%) 0px 8px 24px;
  border-radius: 3%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 95%;
  max-width: 600px;
  padding: 100px 30px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  gap: 20px;
}

.modal-header {
  justify-content: center;
  position: relative;
  font-weight: 700;
  font-size: 25px;
  /* border-bottom: 1px solid #eeeeee; */
  color: #333333;
  text-align: center;
  /* justify-content: space-between; */
}

.modal-footer {
  /* border-top: 1px solid #eeeeee; */
  justify-content: center;
  /* flex-direction: column; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  text-align: center;

  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */

  text-align: center;
  letter-spacing: -0.011em;
}

.close-btn {
  color: #333333;
  background: white;
  border: 1px solid $gray;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.029em;
  border-radius: 28px;
  height: 40px;
  width: 150px;

  &:hover,
  :active {
    background-color: $grayVlight;
    border-color: $gray;
  }
}

.btn-green {
  color: #333333;
  background: #fec443;
  border: 1px solid white;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.029em;
  border-radius: 28px;
  height: 40px;
  width: 150px;
}
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}
</style>
