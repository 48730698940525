<template>
  <div class="input-container">
    <div>
      <label class="tf-label" :for="id">
        {{ label }}
        <strong v-if="required">
          <sup class="fhb-red">*</sup>
        </strong>
      </label>
      <div :class="missing || invalid ? 'password-ctn-error' : 'password-ctn'">
        <input
          type="password"
          :value="modelValue"
          :id="id"
          :class="missing || invalid ? 'll-textfield-error password-ctn-error' : 'll-textfield '"
          :aria-describedbyy="ariaDescribe"
          :name="name"
          :data-test="test"
          :aria-required="required"
          @blur="handleBlur"
          placeholder="Password"
          autocomplete="password"
          :disabled="disable"
          :required="required"
        />
        <div class="svg-container" @click="togglePasswordVisible">
          <img
            class="view"
            v-if="type == 'password'"
            srcset="@/assets/images/pw-viewable.png 1x, @/assets/images/pw-viewable-2x.png 2x"
            src="@/assets/images/pw-viewable.png"
            title="View Password"
          />
          <img
            v-else
            src="@/assets/images/pw-hidden.png"
            srcset="@/assets/images/pw-hidden.png 1x, @/assets/images/pw-hidden-2x.png 2x"
            title="Hide Password"
          />
        </div>
      </div>
      <div class="error-container">
        <div class="error" v-if="missing || invalid" role="alert" :id="ariaDescribe">
          <img
            srcset="
              @/assets/images/exclaimation-lg.png    1x,
              @/assets/images/exclaimation-lg-2x.png 2x
            "
            src="@/assets/images/exclaimation-lg.png"
            title="account number error"
          />
          <div v-if="missing" id="empty">Please enter a {{ label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  emits: ["blur", "update:modelValue"],
  props: {
    modelValue: String,
    label: String,
    id: String,
    missing: Boolean,
    invalid: Boolean,
    required: Boolean,
    ariaDescribe: String,
    name: String,
    test: String,
    disable: Boolean,
  },
  data() {
    return {
      element: null,
      formatted: "",
      raw: "",
      type: "password",
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        return 91
      },
    },
  },
  methods: {
    getBrowser() {
      let agent = navigator.userAgent
      if (agent.match("/chrome|chromium|crios/i")) return "chrome"
      if (agent.match("/firefox|fxios/i")) return "firefox"
      if (agent.match("/safari/i")) return "safari"
      if (agent.match("/edg/i")) return "edge"
    },
    handleBlur(e) {
      var x = document.getElementById(this.id)
      x.type = "password"
      this.$emit("update:modelValue", e.target.value.trim())
      this.$emit("blur")
    },
    togglePasswordVisible() {
      var x = document.getElementById(this.id)
      x.type = x.type == "password" ? "text" : "password"
      this.type = this.type == "password" ? "text" : "password"
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.input-container {
  div {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  input {
    border: none;
    box-shadow: inset 0px 1px 0 0 rgba(176, 176, 176, 0.4);
  }
  .svg-container {
    width: 3em;
    height: 40px;
    padding: 10px 10px;
    bottom: auto;
    vertical-align: middle;
    position: relative;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    :hover {
      cursor: pointer;
    }
    @include media-breakpoint-up(sm) {
      height: 46px;
    }
  }
}
.password-ctn {
  border-radius: 3px;
  border: 1px solid $grayMid;
  display: flex;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 0 0 rgba(176, 176, 176, 0.4);
}
.password-ctn-error {
  border-radius: 3px;
  border: 1px solid $red;
  display: flex;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 0 0 rgba(176, 176, 176, 0.4);
  background-color: $redFaint;
}
.ll-textfield-error {
  border-radius: 0px;
}
.error-container {
  height: 30px;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.view {
  padding-top: 4px;
}
</style>
