const loginURLs = [
  "https://fhbonline.fhb.com/firsthawaiianbankonline_test_733926_2/uux.aspx#/ssoDirect/20", // staging
  "https://fhbonline.fhb.com/firsthawaiianbankonline/uux.aspx#/ssoDirect/17", // prod
]
const enrollURL = [
  "https://enrollment.staging.fhb-eng.com/enroll",
  "https://enrollment.fhb.com/enroll",
]
const retailBaseURL = ["https://enrollment.staging.fhb-eng.com/", "https://enrollment.fhb.com/"]

const forgotPassword = [
  "https://fhbonline.fhb.com/firsthawaiianbankonline_test_733926_2/uux.aspx#/login/resetPasswordUsername",
  "https://fhbonline.fhb.com/firsthawaiianbankonline/uux.aspx#/login/resetPasswordUsername",
]
const enrolledLoginURLs = [
  "https://fhbonline.fhb.com/firsthawaiianbankonline_test_733926_2/uux.aspx#/login/", // staging
  "https://fhbonline.fhb.com/firsthawaiianbankonline/uux.aspx#/login/", // prod
]
const newEnrollmentURLs = [
  "https://enrollment.staging.fhb-eng.com/funding-enrollment", // staging
  "https://enrollment.fhb.com/funding-enrollment", // prod
]

const q2OpenAccountURLs = [
  "https://grouse1-uat.s3.amazonaws.com/3395-01/index.html?skipCrit=true", // staging
  "https://deposits.fhb.com/?skipCrit=true",
]

export default {
  q2OpenAccountURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return q2OpenAccountURLs[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return q2OpenAccountURLs[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return q2OpenAccountURLs[0]
    } else {
      return q2OpenAccountURLs[1]
    }
  },
  loginURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return loginURLs[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return loginURLs[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return loginURLs[0]
    } else {
      return loginURLs[1]
    }
  },
  enrolledLoginURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return enrolledLoginURLs[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return enrolledLoginURLs[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return enrolledLoginURLs[0]
    } else {
      return enrolledLoginURLs[1]
    }
  },
  enrollmentURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return enrollURL[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return enrollURL[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return enrollURL[0]
    } else {
      return enrollURL[1]
    }
  },
  forgotUsernameURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return `${retailBaseURL[0]}forgotusername`
    } else if (window.location.href.indexOf("staging.") > -1) {
      return `${retailBaseURL[0]}forgotusername`
    } else if (window.location.href.indexOf("localhost") > -1) {
      return `${retailBaseURL[0]}forgotusername`
    } else {
      return `${retailBaseURL[1]}"forgotusername`
    }
  },
  forgotPasswordURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return forgotPassword[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return forgotPassword[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return forgotPassword[0]
    } else {
      return forgotPassword[1]
    }
  },
  accountRecoveryURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return `${retailBaseURL[0]}accountrecovery`
    } else if (window.location.href.indexOf("staging.") > -1) {
      return `${retailBaseURL[0]}accountrecovery`
    } else if (window.location.href.indexOf("localhost") > -1) {
      return `${retailBaseURL[0]}accountrecovery`
    } else {
      return `${retailBaseURL[1]}accountrecovery`
    }
  },
  newEnrolledURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return newEnrollmentURLs[0]
    } else if (window.location.href.indexOf("staging.") > -1) {
      return newEnrollmentURLs[0]
    } else if (window.location.href.indexOf("localhost") > -1) {
      return newEnrollmentURLs[0]
    } else {
      return newEnrollmentURLs[1]
    }
  },
}
