<!-- Path: /return/enhanced-security -->
<template>
  <div class="page page-bg-color">
    <div class="form-center">
      <FormContainer>
        <template #form-content>
          <div class="headerLog">
            <a href="https://www.fhb.com/"
              ><img
                class="header-img"
                srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
                src="@/assets/images/FHBColorLogo-2x.png"
                alt="First Hawaiian Bank home logo"
                title="FHB home"
            /></a>
          </div>
          <div class="id-logo">
            <img
              class="header-logo"
              srcset="@/assets/images/id-card.png 1x, @/assets/images/id-card-2x.png 2x"
              src="@/assets/images/FHBColorLogo-2x.png"
              alt="First Hawaiian Bank home logo"
              title="FHB home"
              aria-hidden="true"
            />
          </div>
          <div class="content-title">
            <h1>Enhanced security</h1>
          </div>
          <div class="content-text" data-test="enhanced-security-lname">
            <p class="content-text-p">
              To confirm your identity, we will send a secure access code to the contact method of
              your choice.
            </p>
          </div>
          <div class="send-code">
            <div v-if="mfaOptions">
              <RadioToggleButtons
                :inputValues="mfaOptions"
                id="mfa-method"
                name="mfa-method"
                v-model="mfa_option"
                :disable="processing"
                legendText="Where should we send your code?"
                dataTest="mfa-method"
                :required="true"
                :missingSelection="missingMFAMethod"
                errorMsg="Please select a contact method"
              />
            </div>
            <div>
              <h3 v-if="mfa_option">
                We'll send your code to the following
                {{
                  mfa_option && mfaOptions[mfa_option].contact_type == "EMAIL"
                    ? "Email"
                    : "Phone Number"
                }}:
              </h3>
              <span v-if="mfa_option" class="content-text-p">{{
                mfaOptions[mfa_option].contact
              }}</span>
            </div>
          </div>
          <div v-if="api_err" role="alert" class="api-error-box">
            {{ this.api_err }}
          </div>
          <div class="button-container">
            <button
              v-if="!processing"
              :disabled="mfa_option == null"
              :class="mfa_option != null ? 'submitBtn' : 'grayOutBtn'"
              @click="sendCode"
              id="enhanced-security-send-code-btn"
              data-test="enhanced-security-send-btn"
            >
              {{ "SEND CODE" }}
            </button>
            <button
              v-else
              disabled
              class="grayOutBtn"
              id="enhanced-security-processing-btn"
              data-test="enhanced-security-processing-btn"
            >
              PROCESSING...
            </button>
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import jQuery from "jquery"
import RadioToggleButtons from "@/components/Inputs/RadioToggleButtons.vue"
import FormContainer from "@/components/FormContainer.vue"
import api from "@/app/funding_api.js"

export default {
  name: "MFAEnhancedSecurity",
  components: { RadioToggleButtons, FormContainer },
  data() {
    return {
      deliverMethod: "",
      emailPayload: "",
      mobilePayload: "",
      missingMFAMethod: false,
      processing: false,
      mfa_option: null,
      mfaOptions: null,
      api_err: "",
    }
  },
  created() {},
  computed: {
    ...mapGetters("fundnow", ["getMFAPhones", "getMFAEmails", "getFundingID"]),
    mfa_phones: {
      get() {
        return this.getMFAPhones
      },
    },
    mfa_emails: {
      get() {
        return this.getMFAEmails
      },
    },
  },

  methods: {
    ...mapActions("fundnow", ["setMFAInfo", "setMFAMethod"]),
    // maskPhone(phone) {
    //   let maskphone = phone.replace('+1', '').replace("(", '').replace(")", '').replace('-', '').replaceAll(' ', '')
    //   let maskPhoneNum = "*********" + maskphone.slice(6, 10)
    //   return maskPhoneNum
    // },
    // maskEmail(email) {
    //   const upperEmail = email.toUpperCase()
    //   let lastpartIndex = upperEmail.indexOf('@')
    //   let maskedEmail = upperEmail.slice(0, 3) + "******" + upperEmail.slice(lastpartIndex,)
    //   return maskedEmail
    // },
    async requestOTPCode(payload) {
      this.api_err = ""
      this.processing = true
      const resp = await api.returnToAppMFASendCode(this.getFundingID, payload)
      if (!resp) {
        this.api_err = `Network error occurred${
          resp?.data?.response ? ": " + resp.data.response : "."
        }`
      } else {
        // cooldown not completed
        if (resp.status == 422) {
          this.api_err =
            "We are unable to send the verification code at this time. Please try again later."
        } else if (resp.status != 200 && resp.status != 422) {
          // some other error
          this.api_err = `Network error occurred${
            resp?.data?.response ? ": " + resp.data.response : "."
          }`
        } else {
          // success
          if (this.mfaOptions[this.mfa_option].contact_type == "EMAIL") {
            this.$router.push("/return/mfa-email")
          } else this.$router.push("/return/mfa-mobile")
        }
      }
      this.processing = false
    },

    // sends code and does check to see which mfa method wsa chosen.
    // may need changing to be done once integrated with BE
    sendCode() {
      const payload = {
        method: this.mfaOptions[this.mfa_option].contact_type,
      }
      this.requestOTPCode(payload)
    },
  },
  mounted() {
    var options = []
    for (let i in this.mfa_phones) {
      options.push({
        contact_type: "SMS",
        contact: this.mfa_phones[i],
      })
    }
    for (let i in this.mfa_emails) {
      options.push({
        contact_type: "EMAIL",
        contact: this.mfa_emails[i],
      })
    }
    this.mfaOptions = options
    jQuery("html, body").animate({ scrollTop: 0 })
  },
}
</script>

<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
#main {
  padding-left: 20px;
  padding-right: 20px;
}
#enhanced-security-send-code-btn {
  font-size: 0.8rem;
}
.button-container {
  text-align: center;
}

.submitBtn {
  height: 55px;
  max-width: 277px;
  width: 100%;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

.grayOutBtn {
  height: 55px;
  max-width: 277px;
  width: 100%;
  position: relative;
  background-color: #e7e7e7;
  border: 1px solid white;
  color: #44444b;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

.grayOutBtn:hover {
  cursor: default !important;
  background-color: #e7e7e7 !important;
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.headerLog {
  text-align: center;
  margin-top: 36px;
}
.header-img {
  max-width: 227px;
  max-height: 24.5px;
  width: 100%;
  height: 100%;
}
.header-logo {
  max-width: 77px;
  max-height: 111px;
  width: 100%;
  height: 100%;
}

.id-logo {
  text-align: center;
  margin-top: 40px;
}

.content-title {
  text-align: center;
  font-size: 28px;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    word-wrap: break-word;
  }
}

.content-text {
  text-align: center;
  font-size: 18px;
  font-weight: 450;
}

.content-text-p {
  margin: 0px;
}

.send-code {
  /* margin-top: 40px; */
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
}
.form-center {
  // margin-top: 4vw;
  position: relative;
  margin-top: -30px;
  padding-top: 3vw;
}
.page-bg-color {
  background-color: $orangeFaint;
}
</style>
