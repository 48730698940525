import axios from "axios"

const FUNDING_BACKEND_URL = process.env.VUE_APP_API_URL

export default {
  async createCustomerAccount(payload) {
    const endpoint = `${FUNDING_BACKEND_URL}/test/deposits/create`

    return axios
      .post(endpoint, payload, null)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },

  async getRedirectURL(funding_flow_id, ssn) {
    const endpoint = `${FUNDING_BACKEND_URL}/test/deposits/funding/sso`

    const payload = {
      data: {
        funding_flow_id,
        ssn,
      },
    }

    return axios
      .post(endpoint, payload, null)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
}
