<!-- path: '/external/transfer-timeout -->
<template>
  <div class="page">
    <Splash />
    <div class="center">
      <FormContainer header="Verify Your Account">
        <!-- use slot -->
        <template #form-content>
          <div class="form-header">
            <div>
              <img
                srcset="@/assets/images/failed-error.png 1x, @/assets/images/failed-error-2x.png 2x"
                src="@/assets/images/failed-error.png"
                title="Deposits failed"
              />
            </div>
            External Transfer Unsuccessful
          </div>

          <div class="v-spacer-20" />
          <div class="failed-msg">
            Your deposit is currently pending. Please allow up to 3 business days for your
            transaction to post/review on our end.
          </div>
          <div class="v-spacer-20" />
          <div style="text-align: center; margin-top: 50px">
            Need help? Call us at (808) 844-4545 or toll free at (800) 894-5600
          </div>
        </template>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormContainer from "@/components/FormContainer.vue"
import Splash from "@/components/Splash.vue"

export default {
  name: "InternalTransferUnsuccessful",
  components: {
    FormContainer,
    Splash,
  },
  data() {
    return {
      missingSelectedAccount: false,
      processing: false,
      next: false,
    }
  },
  methods: {
    ...mapActions("fundnow", []),
  },
  computed: {
    ...mapGetters("fundnow", []),
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.close-btn {
  width: 200px;
  height: 40px;
  border-radius: 999px;
  padding: 4px 24px;
  border: 1px solid black;
  background-color: $white;
  cursor: pointer;
  &:hover,
  :active {
    background-color: $grayVlight;
  }
}
.small-checkmark {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 5px;
}
.deposits-sent-info {
  margin: 10px 0;
}
.failed-msg {
  font-size: 22px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
}
.close-container {
  text-align: center;
  margin-top: 60px;
}
</style>
