<template>
  <div class="input-container">
    <div>
      <label class="tf-label" :for="id">
        {{ label }}
        <strong v-if="required">
          <sup class="fhb-red">*</sup>
        </strong>
      </label>
      <input
        type="text"
        :value="modelValue"
        :id="id"
        :class="missing || invalid ? 'll-textfield-error' : 'll-textfield '"
        :aria-describedby="ariaDescribe"
        :name="name"
        :data-test="test"
        :aria-required="required"
        @blur="handleBlur"
        :placeholder="label"
        :disabled="disable"
        :required="required"
      />
      <div class="error-container">
        <div v-if="missing || invalid" class="error" :id="ariaDescribe" role="alert">
          <img
            srcset="
              @/assets/images/exclaimation-lg.png    1x,
              @/assets/images/exclaimation-lg-2x.png 2x
            "
            src="@/assets/images/exclaimation-lg.png"
            title="account number error"
          />
          <div v-if="missing" id="empty">Please enter {{ label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserID",
  emits: ["blur", "update:modelValue"],
  props: {
    modelValue: String,
    label: String,
    id: String,
    missing: Boolean,
    invalid: Boolean,
    required: Boolean,
    ariaDescribe: String,
    name: String,
    test: String,
    disable: Boolean,
  },
  data() {
    return {
      element: null,
      formatted: "",
      raw: "",
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        return 91
      },
    },
  },
  methods: {
    getBrowser() {
      let agent = navigator.userAgent
      if (agent.match("/chrome|chromium|crios/i")) return "chrome"
      if (agent.match("/firefox|fxios/i")) return "firefox"
      if (agent.match("/safari/i")) return "safari"
      if (agent.match("/edg/i")) return "edge"
    },
    handleBlur(e) {
      this.$emit("update:modelValue", e.target.value.trim())
      this.$emit("blur")
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.input-container {
  div {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  input {
    box-shadow: inset 0px 1px 0 0 rgba(176, 176, 176, 0.4);
  }
}
.error-container {
  height: 30px;
}
.error {
  color: $red;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  font-size: 15px;
  font-weight: 450;
  line-height: 23px;
  img {
    width: 15px;
    height: 15px;
  }
}
.ll-texfield {
  max-width: 1000px;
}
</style>
